.static-nav {
    margin-left: 2.438rem;
    padding: 1.25rem 0rem 3.375rem 0.75rem;
    margin-top: 2rem;
    position: sticky !important;
    top: 5rem;
}
.static-nav ul {
    padding-left: 0;
    list-style-type: none;
}
.static-nav li {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    /* identical to box height */

    /* RB text */
    color: #333333;
    margin-top: 1rem;


}
.collection-footer {
    margin-top: 1.25rem;
    margin-bottom: 1rem;
}
.form-check-input:checked {
    background: #202020 ;
    border-color: #202020 !important;
    color: white;
}
.accordion-button:not(.collapsed){
    color: #202020;
}
.accordion-header{
    font-size: 14px;
    color: #333333;
    padding-top: 1rem;
}
.accordion-header .accordion-button {
    font-size: 14px !important;
}
.accordion-body {
    padding-top: 0 !important;
}
.collection-footer h6 {
    color: #522FFF;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}
.static-nav li a  {
    text-decoration: none;
    color: #333333;
}
.static-nav li a:hover{
    font-weight: 500;
    text-decoration: underline;
}
.static-nav h6 {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #070707;
    margin-top: 0 !important;
}
.form-check-input[type="checkbox"] {
    border-radius: .1rem !important;
    margin-right: 1rem !important;
}
.methods .history-table {
    table-layout: auto;
}
.methods .history-table td {
    padding-left: 2%;
}
.methods .history-table td:nth-child(1) {
    width: 20%;
    color: #202020;
}
.methods .history-table td:nth-child(2) , .methods .history-table th:nth-child(2) {
    width: 60%;
    padding-right: 5%;
}
.history-table {
    width: 100%;
    table-layout: fixed;
    word-wrap: break-word;
}
.history-tag {
    margin: 1%;
    display: inline-block;
    border-radius: 200px;
    background: #F2F2F2;
    font-weight: 400 !important;
    color: #202020 !important;
    padding: .3rem .5rem;
}
.history-table th {
    height: 44px;
    background: #F9F9FA;
    font-size: 14px;
    padding-left: 2%;
    border-bottom: 1px solid #E7E7E9;
    border-top: 1px solid #E7E7E9;
    color: #7E7E7E;
    font-weight: 400;
}
.history-table td {
    height: 4.188rem;
    border-bottom: .5px solid rgba(235, 235, 235, 0.9);
    font-size: 14px;
    padding: 1%;
    color: #545454;
}
.history-table .title {
    color: #00944D;
    cursor: pointer;
}
.mobile .history-table th {
    font-size: 14px;
}
.mobile .history-table th:first-child {
    font-size: 14px;
    padding-left: 1rem;
}
.mobile .history-table td {
    font-size: 14px;
}
.mobile .history-table td:first-child {
    padding-left: 1rem;
}
.h6-filter {
    font-family: 'Circular Std Book', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 17px;

    /* RB active/RB success */
    color: #00944D;
    cursor: pointer;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    padding: 6px;
}
.mobfilter1 {
    border-radius: 5px;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
}
.mobfilter h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #070707;
}
.mobfilter h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    color: #070707;
}
.mobfilter .container-fluid h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    color: #070707;
}
.mobfilter .form-check-label {
    font-size: 12px;
}
.mobfilter .bg-white {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.mobile .lower-hidden button {
    background-color: #070707;
    color: white;
    padding: 15px 66px;
    font-size: 12px;
}
.lower-hidden button {
    background-color: #070707;
    color: white;
    padding: 15px 66px;
}
.mobile .lower-hidden h6{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #333333;
}
.lower-hidden h6{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #333333;
}
.mobile .lower-hidden p{
    font-size: 14px;
}
.passed-create td {
    opacity: .5;
}
.resources-search-section {
    margin-top: 5.563rem;

}
.tablet .resources-search-section {
    margin-top: 2.563rem;

}
.mobile .resources-search-section h4 {
    font-style: normal;
    font-weight: 700 ;
    font-size: 30px;
    line-height: 43px;
    color: #070707;
    text-align: center;
}
.resources-search-section h4 {
    font-style: normal;
    font-weight: 700 ;
    font-size: 40px;
    line-height: 50px;
    color: #070707;
}

.resources-search-section h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #333333;
    margin-top: 1.5rem;
}
.resources-searchbox {
    margin-top: 3rem;

}
.resources-searchbox .search-section {
    padding-left: 10.25rem;
    padding-right: 10.25rem;
}
.filter-title {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 17px;
    text-align: center;

    /* Dark grey */
    color: #767676 !important;
    margin-top: 20px;
    margin-bottom: 20px;
}
.btn-save-filter {
    width: 100%;
    background: #070707;
    color: white;
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    height: 38px;
    border-radius: 0;
}
.mobile .form-check-label {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 20px;
    color: #333333;
}
.mobile .form-check-input {
    height: 12px !important;
    width: 12px !important;

}
.resources-td h6 {

    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    /* RB black */
    color: #070707;
}
.course-price {
    background: rgba(255, 230, 230, 0.9);
    border-radius: 4px;
    padding: 7px 9px;
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    /* RB text */
    color: #333333;
    width: fit-content;
    margin: 0 !important;
}
.free-course {
    background: #D7F3E6;
    border-radius: 4px;
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
    text-align: center;
    padding: 7px 9px;
    width: fit-content;
    margin: 0 !important;
}
.resources-td p {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #333333;


}

/*-----Pagination-----*/
.pagination {
    display: flex;
    list-style: none;
    margin-top: 20px;
    padding: 0;
}

.pagination a {
    cursor: default;
    border-radius: 5px;
    margin-left: 10px !important;
    color: #333333 !important;
}

.pagination li:not(.disabled) a:hover {
    border: 1px solid #333333;
    cursor: pointer;
    color: #333333 !important;
    background-color: white;
}

.pagination li a {
    font-weight: bold;
    border: 0;
    height: 2.125rem;
    width: 2.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pagination .active {
    border-radius:  2.43774px !important;
}
.pagination li.active a {
    color: #fff !important;
    background: #333333 !important;
    border: 1px solid #333333 !important;
    height: 2.125rem;
    width: 2.125rem;
    border-radius:  2.43774px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination li.disabled a {
    pointer-events: none;
    color: rgb(198, 197, 202);
    border: 1px solid rgb(198, 197, 202);
    display: none;
}
.saved-sources .row {
    padding-left: 1rem;
    padding-right: 1rem;
}
.saved-sources h6 {
    margin-left: 1rem;
    margin-top: 1.5rem;
}
.notion-breadcrumbs::-webkit-scrollbar {
    -webkit-appearance: none !important;
    -ms-overflow-style: none !important;  /* IE and Edge */
    scrollbar-width: none !important;
    display: none !important;
}
.notion-breadcrumbs:hover{
    -webkit-appearance: none !important;
    -ms-overflow-style: none !important;  /* IE and Edge */
    scrollbar-width: none !important;
}
.notion-breadcrumbs{
    -webkit-appearance: none !important;
    -ms-overflow-style: none !important;  /* IE and Edge */
    scrollbar-width: none !important;
}
.tags-list:hover{
    -webkit-appearance: none !important;
    -ms-overflow-style: none !important;  /* IE and Edge */
    scrollbar-width: none !important;
}
.tags-list{
    -webkit-appearance: none !important;
    -ms-overflow-style: none !important;  /* IE and Edge */
    scrollbar-width: none !important;
}
