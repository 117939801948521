.notion-breadcrumbs a {
    color: #070707;
}

.notion-breadcrumbs ul {
    list-style-type: none !important;

}

.mobile .notion-breadcrumbs {
    padding-left: 1rem;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.04);
    margin-top: 1.188rem;
    height: 35px !important;
}
.mobile .notion-breadcrumbs ul {
    list-style-type: none !important;
    padding-left: 0;
    white-space: nowrap;
    overflow-x:scroll;
    overflow-y: hidden;
    padding-bottom: 1rem;
}
.mobile .notion-breadcrumbs ul::-webkit-scrollbar{
    display: none !important;
}
.notion-breadcrumbs li {
    float: left;
    margin-left: 16px;
    list-style-type: none !important;
    padding-right: 1%;
    margin-top: 2rem;
    line-height: 35px;
}
.notion-breadcrumbs {
   text-align: center !important;
    padding-bottom: 2rem !important;
    padding-left: 5rem;
    padding-right: 5rem;
}
.notion-breadcrumbs a {
    display: inline-block;
    margin-left: 16px;
    list-style-type: none !important;
    border: 1px solid #7E7E7E;
    padding: .4rem 1.5rem;
    text-align: center;
    font-family: "Circular Std Book", sans-serif;
    font-size: 14px;
    color: #545454;
    margin-bottom: 0;
    border-radius: 200px;
    margin-top: 1rem;

}
.mobile .notion-breadcrumbs li {
    margin-left: 16px;
    list-style-type: none !important;
    border-right: 1px solid #333333;
    padding-right: 1%;
    margin-top: 0;
    display: inline-block;
    float: unset;

}
.mobile .notion-breadcrumbs a {
    margin-left: 16px;
    list-style-type: none !important;
    border-right: 1px solid #333333;
    padding-right: 16px;
    display: inline-block;
    float: unset;
    font-size: 14px !important;

}
.mobile .notion-breadcrumbs li span {
    font-size: 14px;
    padding-right: 10px;
}
.notion-breadcrumbs li:first-child{
    margin-left: 0;
}
.notion-breadcrumbs a:first-child{
    margin-left: 0;
}
.notion-breadcrumbs li:last-child {
    border-right: none !important;
}
.notion-breadcrumbs:last-child {
    border-right: none !important;
}
.tags ul {
    list-style-type: none;
    padding-left: 0;
}
.tags ul:first-child {
    margin-top: 0px;
}
.tags li {
    height: 35px;
    margin-top: 10px;
}
.bread-active {
    font-weight: 500;
    font-size: 16px;
    border-radius: 4px;
    border-spacing: 10px;
    padding-bottom: 5px;
    background: #E5E5E5;
    border: none !important;
    color: #1D1B20 !important;
}
.mobile .bread-active {
    font-weight: 500;
    font-size: 14px;
    color: #522FFF;
    border-bottom: 4px solid #522FFF;
    border-radius: 4px;
    border-spacing: 10px;
    padding-bottom: 5px;
}
.mobile .active-tag {
    background: #F6F4FF;
    display: flex;
    align-items: center;
    padding-left: 10px;
    border: 1px solid #BBBBBB;

}
.active-tag {
    background: #F6F4FF;
    border-left: 5px solid #AB9AFF;
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.tags-title {
    font-family: 'Kumbh Sans', sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 17px;

    /* RB active/RB success */
    color: #767676 !important;
}
.tags-title-active {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    /* Dark grey */
    color: #767676;


}
.tags-title-icon {
    color: #00944D !important;
    font-size: 20px;
}
.tags-title-icon-active {
    color: #767676 !important;
    font-size: 20px;
}
.tagname {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #070707;
    margin-top: 1.875rem;
}
.input-group-text-notion {
    border-radius: 0 300px 300px 0 ;
    background-color: white;
    border: none;
    font-size: 20px;
    padding: 0 !important;
}
.notion .search-field-text {
    padding: 0 !important;

}
 .tags-container {
    border-radius: 4px;
    padding-left: 1rem;
}
.tags-container ul {
    white-space: nowrap;
    overflow-x: scroll;
    list-style-type: none;
}
 .tags-container li {
    display: inline-block;
    border: 1px solid #BBBBBB;
     text-align: center;
     padding: 0.781rem 1.25rem;
     height: unset;
     margin-right: 0.625rem;
     border-radius: 100px;
     font-weight: 400;
     font-size: 14px;
     line-height: 17px;
     color: #767676;
}
 .tags-container li:hover{
     display: inline-block;
     border: 1px solid #BBBBBB;
     text-align: center;
     padding: 0.781rem 1.25rem;
     height: unset;
     margin-right: 0.625rem;
     border-radius: 100px;
     font-weight: 400;
     font-size: 14px;
     line-height: 17px;
     color: #767676;
     background: #F6F4FF !important;
     align-items: center;
     cursor: pointer;
 }
.tags-container ul::-webkit-scrollbar {
    -webkit-appearance: none !important;
    display: none !important;
}
