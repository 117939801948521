.paymentheader {
    margin-top: 3.813rem;
    padding-left: 25rem;
    padding-right: 25rem;
}
.tablet .paymentheader {
    margin-top: 3.813rem;
    padding-left: 5rem;
    padding-right: 5rem;
}
.mobile .paymentheader {
    margin-top: 3.813rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}
.tablet-paymentDetails {
    margin-top: 3.063rem;
}
.paymentheader .form-check-input[type="checkbox"] {
    border-radius: 200px !important;
}
.paymentheader .form-check-input:checked {

}
.paymentDetails {
    margin-top: 6.063rem;
}

.mobile .paymentDetails {
    margin-top: 3.063rem;
}
.tablet .paymentDetails {
    margin-top: 1.063rem;
}
.paymentDetails .card-body {
    padding-top: 3.813rem;
    padding-left:1.688rem;
    padding-right:1.688rem;
    padding-bottom: 1.688rem;
    border: 1px solid #E1E1E1;
    border-radius: 12px;
}
.paymentDetails .price {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 46px;
    color: #000000;
}
.paymentDetails .period {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: #A2A2A2;
}
.paymentClass {
    margin-top: 1.25rem;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    color: #000000;
}
.mobile .planItems {
    margin-top: 1.625rem;
}
.tablet .planItems {
    margin-top: 1.625rem;
}
.planItems {
    margin-top: 4.625rem;
}
.planItems h6 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #333333;
}
.planItems p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #333333;
}
.planItemIcon {
    height: 1.25rem;
    width: 1.25rem;
    background: rgba(82, 67, 194, 0.15);
    margin-right: 0.625rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
}
.paymentheader h4 {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    /* RB black */
    color: #070707;
}
.paymentheader p {
    margin-top: 2.375rem;
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;

    /* identical to box height, or 114% */
    text-align: center;

    /* RB text */
    color: #333333;
}
.yearDiscount {
    background: #DFD9FF;
    border-radius: 2px;
    font-family: 'Kumbh Sans',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 11px;
    color: #333333;
    padding: 3px 8px 2px 7px;

}
.btn-payment {
    border-radius: 24px;
    background-color: #000000;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    width: 100%;
    margin-top: 2.188rem;
    height: 45px;
}
.btn-payment-dark {
    border-radius: 24px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    background-color: white;
    text-align: center;
    color: #000000;
    width: 100%;
    margin-top: 2.188rem;
}
/*Dark card body */
.card-body-dark {
    background-color: #000000;
}
.card-body-dark .price {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 46px;
    color: #ffffff;
}
.card-body-dark .period {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: white;
}
.card-body-dark .paymentClass {
    margin-top: 1.25rem;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    color: white;
}
.card-body-dark .planItemIcon {
    height: 1.25rem;
    width: 1.25rem;
    background: #191919;
    margin-right: 0.625rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    color: white;
}
.card-body-dark .planItems h6 {
    color: white;
}
.card-body-dark .planItems p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
}
.card-body-dark .planItems p {
    margin-top: 0.875rem;
}
.card-body-dark .planItems  {
    margin-top: 0rem;
}

/*---Product Descriptions----*/
.productDescriptions {
    margin-top: 6.688rem;
}
.productDescriptions img {
    border-radius: 14px;
}
.productDescriptions .row2 {
    margin-top: 6.063rem;
}
.btn-plans {
    padding: 17px 0px;
    gap: 10px;
    background: #070707;
    border-radius: 6px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF !important;
    width: 197px;
    height: 53px;
}
.mobile .btn-plans {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px
}

/*----Contact sales modal----*/
.contact-sales-modal .form-control {
    background: #FFFFFF;

    /* Grey/Border */
    border: 1px solid #E0E0E0;
    border-radius: 7.64807px;
    height: 54px;
}
.contact-sales-modal label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16.5708px;
    line-height: 20px;
    color: #4F5B76;
    margin-bottom: 5px;
}
.contact-sales-modal h5 {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 2.313rem;
    /* RB black */
    color: #070707
}
.contact-sales-modal {
    padding: 3rem 5.063rem 3.5rem 5.313rem;
}
.text-area {
    height: 6.063rem !important;
}
.btn-cancel-sales {
    padding: 12.7468px 15.2961px;
    width: 100%;
    height: 54px;
    background: #FFFFFF;

    /* Grey/Border */
    border: 1px solid #E0E0E0;
    border-radius: 6px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16.5708px;
    line-height: 20px;
    color: #070707;
}
.btn-confirm-sales {
    padding: 17px 0px;
    gap: 10px;
    width: 100%;
    height: 53px;
    left: 642px;
    top: 865px;

    /* RB black */
    background: #070707;
    border-radius: 6px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF !important;
}
.enterprise-success-span {
    background-color: #00944D;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 29px;
    width: 29px;
    float: left;
}
.enterprise-success h6 {
    float: left;
    width: 80%;
    margin-left: 2%;
    font-family: 'Kumbh Sans', sans-serif !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 22px;
    color: #00944D;
}
.enterprise-success {
    background-color: #EBFFF5;
    height: 108px;
    padding: 32px 65px 32px 40px;
    box-shadow: 0px 25px 61px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

/*Stripe*/
.StripeElement {
    padding: 20px 12px;
    color: #32325d;
    background-color: white;
    height: 55px;
    border: 1px solid #E0E0E0 !important;
    border-radius: 7.64807px;
}
.postal-code {
    padding: 10px 12px;
    color: #32325d;
    background-color: white;
    height: 55px;
    border: 1px solid #E0E0E0 !important;
    border-radius: 7.64807px;
}
.css-1s2u09g-control{
    color: #32325d;
    background-color: white;
    height: 55px;
    border: 1px solid #E0E0E0 !important;
    border-radius: 7.64807px;
}
.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}
.checkout-header h6 {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;

    /* RB black */
    color: #070707;
}
.checkout-buttons {
    margin-top: 2.625rem ;
}
.checkout-header label {
    font-family: 'SF Pro Display', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16.5708px;
    line-height: 20px;
    color: #4F5B76;
    margin-bottom: 5px;
    text-align: left;
}
.pricing-options {
    margin-top: 2.313rem;
    margin-bottom: 12px;
}
.pricing-options .card {
    border: 1px solid #522FFF;
    box-shadow: 0px 2.54936px 5.09871px rgba(0, 0, 0, 0.07);
    border-radius: 7.64807px;
    padding-top: 23px;
    padding-left: 15px;
    padding-bottom: 30px;
}
.pricing-options .card label{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16.5708px;
    line-height: 20px;

    /* identical to box height */

    /* RB 1 */
    color: #522FFF;
}
.pricing-options .card span {
    width: 65px;
    height: 20px;
    background: #522FFF;
    border-radius: 26px;
    color: white;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    padding: 4px 8px;
}
.pricing-options .card .form-check h6 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 17px;
    color: #522FFF;
    margin: 0 !important;

}

/*---------Success page-----*/
.success-page span {
    height: 28px;
    width: 28px;
    border-radius: 50%;
    border: 1.5px solid #200E32;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    margin-left: 14.38px;
    color: #200E32;
    font-size: 30px;
}
.success-page h5{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center !important;
    color: #242E42
}
.success-page p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-align: center !important;
    color: #8A8A8F;
    margin-top: 1rem;
    margin-left: 20%;
    margin-right: 20%;
}
.success-page h6 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center !important;
    float: left;
    margin-top: 5px;
}
.success-page a {
    margin-top: 2.563rem;
    text-align: center;
}
.tooltip-inner {
    background-color: #767676 !important;
    opacity: 1 !important;
    filter: alpha(opacity=100);
}
.tooltip.show {
    opacity: 1;
}


.tooltip.in {
    opacity: 1 !important;
    filter: alpha(opacity=100);
}
.tooltip-inner .arrow::before{
    background-color: #767676 !important;
}
.tooltip.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: #767676;
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: #767676;
}

.tooltip.bs-tooltip-start .tooltip-arrow::before {
    border-left-color: #767676;
}

.tooltip.bs-tooltip-end .tooltip-arrow::before {
    border-right-color: #767676;
}
.continue-searching {
    height: 60px;
    background: #000000;
    border-radius: 5px;
    color: white;
    padding: 1.063rem 2.625rem;
}
.continue-searching:hover{
    box-shadow: inset 15em 0 0 0 white;
    color: #000000;
    transition: .7s all;
}