.webinarbanner {

}
.webinarbanner h6 {
    color: white;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    margin-top: 2.563rem;
}
.webinarbanner h5 {
    color: white;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
}
.webinarbanner img {

}
.webinar-border::after {
    content: '';
    display: block;
    width: 50%;
    border-top: 3px solid #522FFF;
}
.btn-sign {
    background: #070707;
    color: white;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    padding: 15px 30px;
    margin-top: 2.563rem;
}
.btn-sign:hover{
    background: white;
    color: black;
    transition: .5s ease-in-out;
    border: .5px solid black;
}
.webinar-content {
    border-radius: 8px;
    margin-top: 1rem;
}
.webinar-content h5 {
    color: #202020;
    font-size: 20px;
    line-height: 140%;
    font-weight: bold;
}
.webinar-content p{
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #545454;
}
.webinar-content h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    color: #202020;
    margin-top: 1.5rem;
}
.recordings h6 {
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    color: #000000;
    margin-top:0 !important;
}
.recordings .col-md-4 {
    margin-top: 2rem;
}
.webinarbanner span {
    color: #333333;
    background: #FCFCFF;
    border-radius: 4px;
    width: 48px;
    height: 48px;
    text-align: center;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.5rem;
    margin-right: 0.75rem;
}
.card {
    border: none;
}
.card h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #202020;
}
.card p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #333333;
    margin-top: 0.25rem;
}
.card-body {
    padding: 0 1rem !important;
}
.previous img {
    height: 17.188rem;
    width: 20.5rem;
}
.previous iframe {
    height: 22.875rem;
}
.mobile .previous iframe {
    height: 10.875rem;
}
.tablet .previous iframe {
    height: 15.875rem;
}
.mobile .previous:last-child{

}
.webinarbanner .row {
    margin-top: 1.5rem;
}
.mobile .webinar-content {
    padding-left: 1rem ;
    margin-left: 0;
    margin-right: 1rem;
    overflow: hidden;
}
.previous iframe {
    height: 11.875rem;
}
.tablet .webinar-content {
    margin-left: 3rem;
    margin-right: 3rem;
}
.mobile .webinar-content ul {
    overflow-x: scroll;
    white-space: nowrap;
    width: 100%;
}
iframe {
    width: 100%;
}
.previous iframe {
    height: none;
}
#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif;  width:600px;}
/*----Webinar navigation----*/
.webinar-content ul {
    margin-left: 0;
    list-style-type: none;
    margin-top: 0;
    display: inline-flex;
    margin-bottom: 0;
    padding-left: 0;
}
.webinar-content ul li {
    margin-right: 1rem;
    padding: 0.75rem 1.25rem ;
    cursor: pointer;
}
.webinar-content .active {
    background: white;
}
/*------Mobile-------*/
@media only screen and (max-width: 600px) and (hover: none) {
    .webinarbanner img {
        height: unset;
    }

}
