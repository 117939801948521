.bg-image {
    background-image: url("../../assets/banner 1.png");
}
.coll-filters ul li {
    cursor: pointer;
    padding: .5rem 1rem;
}
.coll-filters ul li:hover {
    background-color: #f3f3f3;
}
.coll-filters .active {
    background-color:#f3f3f3 !important;
    color: dimgray !important;
}
.btn-check:active + .btn-primary:focus, .btn-check:checked + .btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
}
.collec-check-label {
    font-weight: 400 !important;
    font-size: 16px !important;
}
.collec-check:checked + .collec-check-label {
    color: #522FFF !important;
    font-weight: 400;
    font-size: 16px;
}