.supportrb_main{
    margin-top: 4%;
}
.supportrb_main .supportrb_banner{
    margin-bottom: 50px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: left top;
   
}



.supportrb_main .supportrb__about{
    display: flex;
    padding-top: 40px;
    padding-left: 14%;
    padding-right: 15%;
    margin-bottom: 60px;
    
}

.supportrb__about_content h4{
    font-family: 'Kumbh Sans', sans-serif;
    color:#333333;
    width: 80%;
    font-size: 18px;
    margin-bottom: 35px;
    font-weight: 400;
    line-height: 160.02%;
}
.mobile .supportrb__about_content h4{
    font-family: 'Kumbh Sans', sans-serif;
    color:#333333;
    width: 98%;
    font-size: 18px;
    margin-bottom: 35px;
    font-weight: 400;
    line-height: 160.02%;
}


.supportrb_main .supportrb__about .supportrb__about_image .supportrb__about_image_background{
    background-color: #ECE8FF;
    border-radius: 10.8636px;
    width: 220px;
    height: 286.4px;
}

.supportrb_main .supportrb__about .supportrb__about_image .supportrb__about_image_background img{
    position: relative;
    top: 20px;
    left: 17px;
}

.supportrb_main .supportrb__supportcontainer{
    background-color: rgba(249, 248, 255, 1);
    height: 356px;
    text-align: center;  
    
}

.supportrb_main .supportrb__supportsmallcontainer{
    margin-bottom: 250px;
}

.supportrb_main .supportrb__supportlargecontainer{

}

.supportrb_main .supportrb__supportcontainer h4{
    color: #522FFF;
    font-family: 'Kumbh Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 56px;
    padding-top: 40px;

}

.supportrb_main .supportrb__supportcontainer .supportrb__box{
    width: 60%;
    max-height: 950px;
    min-height: 350px;
    position: relative;
    left:20%;
    top:30px;
    padding-bottom: 10px;
    

    background: #FFFFFF;
    box-shadow: -4px 0px 22px rgba(0, 0, 0, 0.05);
    border-radius: 20px;

}

.supportrb_main .supportrb__supportcontainer .supportrb__box h4{
    font-family: 'Kumbh Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 31px;
    /* identical to box height */

    text-align: center;
    color: #333333;
}

.supportrb_main .supportrb__supportcontainer .supportrb__box .supportrb__payment_type_options{
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.supportrb_main .supportrb__supportcontainer .supportrb__box .supportrb__payment_type_options .supportrb__payment_type{
    
    background: white;
    border-radius: 7.64807px;
    border-color: #BBBBBB;
    border-width: 1px;
    color: grey;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 0.813rem 2px;
    cursor: pointer;
    margin-right: 30px;
    width: 240px;
    height: 51px;
 
}

.supportrb_main .supportrb__supportcontainer .supportrb__box .supportrb__payment_type_options .active{
    background: #522FFF;
    color: white;
    border: none;
}

.supportrb_main .supportrb__supportcontainer .supportrb__box .supportrb__amount_suggestion{
    display: flex;
    justify-content: center;
    gap: 28px;
    position: relative;
    left: -10px;
    top:20px;
}

.supportrb_main .supportrb__supportcontainer .supportrb__box .supportrb__amount_suggestion .amount{
    background: white;
    border-radius: 7.64807px;
    border-color:#EBEBEB;
    border-width: 1px;
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
 
    cursor: pointer;
    
    width: 80px;
    height: 65px;

}
.amount{
    background: white;
    border-radius: 7.64807px;
    border: 1px solid #EBEBEB;
    color: #333333;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    width: 80px;
    height: 65px;
    margin-right:0.2rem;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;

}
.amount-active {
    background: #333333;
    border-radius: 7.64807px;
    border: 1px solid #EBEBEB;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    width: 80px;
    height: 65px;
    margin-right: 0.875rem;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
}

.StripeElement {

    
}

.donation_input{

    width: 61%;
    padding: 12px 20px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 2rem;

}


.stripe_submit_button{
    background: #000000;
    border-radius: 5px;
    border:none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    width: 256px;
    height: 56.25px;
    margin-top: 10px;
}


.supportrb_main .supportrb__donation{
    margin-bottom: 30px;
    margin-top: 100px;
}

.supportrb_main .supportrb__donation .header{
    text-align: center;
    font-family: 'Kumbh Sans';
    font-weight: 700;
    font-size: 45px;
    line-height: 56px;
    padding-top: 50px;
}

.supportrb_main .supportrb__donation .supportrb__donation_content{
    display: flex;
    justify-content: center;
    padding-left: 90px;
    padding-right: 50px;
    margin-top: 70px;
    gap:50px;

}

.supportrb__donation_content > div {
    max-width: 396px;
}


.supportrb_main .supportrb__donation .supportrb__donation_content h3{
    color: #333333;
    font-family: 'Kumbh Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 31px;
    margin-bottom: 15px;
    
}

.supportrb_main .supportrb__volunteer{
    background: #F9F8FF; 
    height: 313px;
    text-align: center;
    padding-left: 30%;
    padding-right: 30%;
    padding-top: 50px;
}

.supportrb_main .supportrb__volunteer h4{
    font-family: 'Kumbh Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160.02%;
    
    
}






.supportrb_main .supportrb__donation .supportrb__donation_content h4{
    color: #333333;
    font-family: 'Kumbh Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160.02%;
}



.supportrb__volunteer_button {
    background: #000000;
    border-radius: 5px;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    margin-top: 20px;
  }
.supportrb__volunteer_button:hover{
    box-shadow: inset 15em 0 0 0 white;
    color: black;
    transition: .7s all;
    border: 1px solid black;
}