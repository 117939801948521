@media only screen and (max-width: 768px) {
    .supportrb_main .supportrb_banner{
        margin-bottom: 50px;
        background-image: url("../../../assets/supportrb_mobile.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: left top;
        height: 526px;
       
    }
    .supportrb_main .supportrb__about{
        display: flex;
        flex-direction: column-reverse;
        padding-top: 0;
        padding-left: 10%;
        margin-bottom: -40px;  
    }
    

    .supportrb_main .supportrb__about .supportrb__about_image .supportrb__about_image_background{
        background-color: #ECE8FF;
        border-radius: 10.8636px;
        width: 148px;
        height: 157px;
    }
    
    .supportrb_main .supportrb__about .supportrb__about_image .supportrb__about_image_background img{
        position: relative;
        top: 20px;
        left: 17px;
        width: 148px;
        height: 144px;
        
    }

    .supportrb_main .supportrb__about h4{
        font-family: 'Kumbh Sans', sans-serif;
        color:rgba(51, 51, 51, 1);
        width: 100%;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-top: 35px;
        line-height: 160%;
    }
    

    .supportrb_main .supportrb__supportcontainer{
        background-color: transparent;
        height: 356px;
        text-align: center;  
        
    }


    .supportrb_main .supportrb__supportcontainer h4{
        color: #522FFF;
        font-family: 'Kumbh Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 35px;
        line-height: 56px;
        padding-top: 40px;
        
    
    }

    .supportrb_main .supportrb__supportcontainer .supportrb__box{
        width: 100%;
        max-height: 950px;
        min-height: 350px;
        position: block;
        left:0;
        top:0;
        padding-bottom: 10px;
        background: transparent;
        box-shadow: none;
        border-radius: 0;
    
    }

    .supportrb_main .supportrb__supportcontainer .supportrb__box h4{
        font-family: 'Kumbh Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 31px;
        text-align: center;
        color: #333333;
        margin-top: -20px;
    }


    .supportrb_main .supportrb__supportcontainer .supportrb__box .supportrb__payment_type_options{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 25px;
    }

    .supportrb_main .supportrb__supportcontainer .supportrb__box .supportrb__amount_suggestion{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        left: 0;
    }

    .donation_input{
        position: relative;
        top:40px;
        width: 85%;
        padding: 12px 20px;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
    
    }

    .StripeElement {
        width: 85%;
        position: relative;
        
        left: 7.5%;
        margin-top: 12%;
        
    }

    .stripe_submit_button{
        background: #000000;
        border-radius: 5px;
        border:none;
        color: white;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        cursor: pointer;
        width: 85%;
        height: 56.25px;
        margin-top: 10px;
    }

    .mobile .supportrb_main .supportrb__donation{
        margin-bottom: 30px;
        margin-top: 2rem;
    }

    .supportrb_main .supportrb__donation .header{
        text-align: center;
        font-family: 'Kumbh Sans';
        font-weight: 700;
        font-size: 40px;
        line-height: 43px;
        padding-top: 50px;
    }
    .supportrb h3 {
        font-family: 'Kumbh Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 31px;

        /* identical to box height */

        /* RB text */
        color: #333333;
    }
    .supportrb h4{
        font-family: 'Kumbh Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        color: #333333;
    }
    .supportrb_main .supportrb__donation .supportrb__donation_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 1rem;
       
        margin-top: 70px;
        gap:50px;
    
    }

    .supportrb_main .supportrb__volunteer{
        background: #F9F8FF; 
        height: 313px;
        text-align: center;
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 50px;
    }



    


   

    
}