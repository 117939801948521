@font-face {
    font-family: "Circular Std Book";
    src: url("../FontsFree-Net-Circular-Std-Book.ttf");
}
.features-container {
    margin-top: 7rem;
}
.mobile .features-container {
    margin-top: 0;
}
.tablet .features-container {
    margin-top: 0;
}
.features-container img {
    width: 100%;
}
.mobile .items-container {
    margin-top: 2rem;
}
.items-container {
    margin-top: 5rem;
}
.mobile .items-container img {
    width: 100%;
}
.tablet .items-container img {
    width: 100%;
}
.items-container img {
    width: 31.063rem;
}
.mobile  .items-container h4 {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 140.02%;
    color: #333333;
}
.tablet  .items-container h4 {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 140.02%;
    color: #333333;
}
.items-container h4 {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 140.02%;
    color: #333333;
}
.items-container p {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;

    /* RB text */
    color: #333333;
}
.items-container button {
    height: 47px;

    /* RB 4 */
    background: #FCFCFF;
    border-radius: 6px;
    padding: 15px 25px;
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    color: #000000;
}
.highlight {
    background-color: #F4F4F4;
    padding: 2rem;
    border-radius: 15px;
    font-family: "Circular Std Book", sans-serif;
}
.highlight h5 {
    font-size: 25px;
    line-height: 120%;
    font-weight: 500;
}
.highlight  p {
    font-size: 14px;
    font-family: "Circular Std Book", sans-serif;
    margin-left: 55px;
    margin-top: .5rem;
}
.highlight .quote {
    background-color: #D7D7D7;
    border-radius: 10px;
    padding: 1rem;
    margin-top: 1.5rem;
}
.highlight .icon-q {
    font-family: "Circular Std Book", sans-serif;
    color: #999999;
    font-size: 55px;
}
