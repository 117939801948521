.search-tags .color1 {
    color: #522FFF;
    border: #522FFF 2px solid;
}
.search-tags .color1:hover {
    background-color: #522FFF;
    color: white;
    border: #522FFF 2px solid;
    transition: .3s ease-in-out;
}
.search-tags .color2 {
    color: rgba(0, 148, 77);
    border: rgba(0, 148, 77) 2px solid;
}
.search-tags .color2:hover {
    background-color: rgba(0, 148, 77);
    border: rgba(0, 148, 77) 2px solid;
    transition: .3s ease-in-out;
    color: white;
}
.search-tags .color3 {
    color: #757095;
    border: #757095 2px solid;
}
.search-tags .color3:hover {
    background-color: #757095;
    border: #757095 2px solid;
    color: white;
    transition: .3s ease-in-out;
}
.search-tags .color4 {
    color: #ED1E98;
    border: #ED1E98 2px solid;
}
.search-tags .color4:hover {
    background-color: #ED1E98;
    border: #ED1E98 2px solid;
    color: white;
    transition: .3s ease-in-out;
}