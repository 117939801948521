.mobile .breadcrumbs h6 a, .mobile .breadcrumbs h6 {
    font-size: 12px;
}
.mobile .breadcrumbs h5 {
    font-size: 14px;
}
.btn-fav {
    padding: 10px 20px;
    background: #070707;
    border-radius: 4px;
    color: white !important;
    font-size: 16px;
    line-height: 17px;
}
.search-message {
    text-align: center;
    padding-bottom: 5%;
    padding-top: 2rem;
}

/*----User settings-----*/
.user-settings {
    margin-top: 7rem;
    padding-left: 15rem;
    padding-right: 15rem;
}
.mobile .user-settings {
    margin-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
}
.tablet .user-settings {
    margin-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
}
.user-settings .card{
    border: 0.5px solid rgba(82, 47, 255, 0.18);
    border-radius: 10px;
    padding: 1.875rem;
    margin-top: 1.875rem;
}
.mobile .user-settings .card{
    border: 0.5px solid rgba(82, 47, 255, 0.18);
    border-radius: 10px;
    padding: .875rem;
    margin-top: .875rem;
}
.tablet .user-settings .card{
    border: 0.5px solid rgba(82, 47, 255, 0.18);
    border-radius: 10px;
    padding: .875rem;
    margin-top: .875rem;
}
.user-settings h4 {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 50px;
}
.mobile .user-settings h4 {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
}
.tablet .user-settings h4 {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
}
.user-settings hr {
    background-color: #E1E1E1;
    height: 1px;
    border: none;
    margin-top: 3.125rem;
}
.mobile .user-settings hr {
    background-color: silver;
    height: 1px;
    border: none;
    margin-top: .125rem;
}
.tablet .user-settings hr {
    background-color: silver;
    height: 1px;
    border: none;
    margin-top: 2.125rem;
}
.user-settings h5 {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;

    /* RB black */
    color: #070707;
    margin-top: 2.188rem;
}
.mobile .user-settings h5 {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 37px;

    /* RB black */
    color: #070707;
    margin-top: .188rem;
}
.tablet .user-settings h5 {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 37px;

    /* RB black */
    color: #070707;
    margin-top: .188rem;
}
.user-settings .card h6 {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 21px;
    color: #333333;
    margin-top: 0 ;
}
.user-settings .avatar-holder {
    height: 7.375rem;
    width: 7.375rem;
    border-radius: 50%;

}
.avatar-holder img {
    height: 7.375rem;
    width: 7.375rem;
    border-radius: 50%;
}
.mobile .avatar-holder {
    height: 4.375rem;
    width: 4.375rem;
    border-radius: 50%;

}
.mobile .avatar-holder img {
    height: 4.375rem;
    width: 4.375rem;
    border-radius: 50%;

}
.avatar-holder{
    display: flex;
    align-items: center;
    justify-content: center;
}
.avatar-holder img {
    border-radius: 50%;
    width: 100%;
}
.mobile .user-settings .avatar-holder {
    background-color: #D9D9D9;
    height: 7.375rem;
    width: 7.375rem;
    border-radius: 50%;
}
.user-settings .form-control {
    border: 1px solid #E0E0E0;
    border-radius: 7.64807px;
    height: 3.438rem;
}