/*----Breadcrumbs-------*/
.originals-breadcrumbs{
    padding-left: .5rem;
    margin-top: 7rem;
}
.originals-breadcrumbs span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

/*-----Originals list------*/
.library-title{
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: #070707;
    margin-top: 46px;
}
.mobile .library-title {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 43px;
    text-align: center;

    /* RB black */
    color: #070707;
}
.filter-title1 {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    /* Dark grey */
    color: #767676;


}
.originals-list-view-items {

}
.originals-list-row:hover{
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}
.open-originals {
    font-family: 'Kumbh Sans',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    /* RB 1 */
    color: #522FFF;
}
.originals-card {
    margin-top: 1.938rem;
}
.mobile .originals-card {
    border: none !important;
    padding: 0;
    box-shadow:none;
}

.originals-card .orig-title {
    color: #202020;
    font-size: 14px !important;
    margin-top: .5rem !important;
    margin-left: 0;
    height: unset;
}
.originals-card .host {
    color: #545454;
    font-size: 12px !important;
    margin-top: .5rem !important;
}
.mobile .originals-card img {
    height: 140px;
    object-fit: cover;
}
.originals-card img {
    object-fit: cover;
}
.originals-card:hover{
    transition: .3s ease-in-out;
    border-radius: 8px;
}
.originals-list .originals-list-title{

}
.originals-list-title h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin-left: 1rem;
}
.originals-details h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #070707;
    margin-top: 5rem;
}
.originals-details .download-link {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #522FFF;
}
.originals-details .container {

}
.popular-container {
    box-shadow: 0 6px 7px rgba(0, 0, 0, 0.05);
    white-space: nowrap;
    overflow-x: scroll;
    padding-left: 1rem !important;
}
.popular-container-item {
display: inline-block;
}

.btn-download-template {
    background: #070707;
    color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 15px 30px 15px 19px;
    gap: 10px;
    border-radius: 6px;
    margin-top: 3rem;
}
.btn-download-template:hover{
    background: white;
    border: 1px solid #070707;
    color: #070707;
    padding: 15px 30px 15px 19px;
    gap: 10px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-top: 3rem;
}

/*-----Originals navigation-------*/
.originals-navigation {
    margin-top: 46px;
}
.originals-toggle::after {
    display: none;
}
.originals-toggle h6 span {
    padding: 10px 30px;
    color: #767676;
    text-decoration: none !important;
    font-size: 18px;
}
.originals-category-filter {
    margin-top: 1% !important;
    padding: 17px 5px;
    box-shadow: 0px 4px 43px rgba(0, 0, 0, 0.13);
    border-radius: 4px;
}
.originals-category-filter .form-check{
    margin-top: .5rem;
}
.originals-category-filter .form-check-label{
    font-size: 14px !important;
}
/*-------List view-------*/
.originals-list-header {
    height: 64px;
    margin-top: 40px;
    display: flex;
    align-items: center;
}
.originals-list-header h6 {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #070707;
}
.origina-header {
    background-image: url("../../../assets/Group 89922.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 4.75rem 0;
}
.origin-content h3 {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 160.02%;
}
.origin-content p {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
}