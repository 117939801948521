@font-face {
    font-family: "Circular Std Book";
    src: url("../FontsFree-Net-Circular-Std-Book.ttf");
}
.new-user-header{
    margin-top: 7.5rem;
}

.new-user-header h3 {
    font-family: "Circular Std Book", sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
}
.new-user-header p {
    font-family: "Circular Std Book", sans-serif;
    font-size: 14px;
    color: #393939;
    margin-top: 1rem;
}
.new-user-header a {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #000000;
    margin-top: 1.75rem;
    text-align: center !important;
    width: 100%;
    display: block;
}
.new-user-content {
    margin-top: 2.563rem;
}
.new-user-content .col-md-4 {
    margin-top: 2.125rem;
}
.new-user-content .col-md-4 h6 {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.833333px;
    color: #000000;
}
.new-user-content .col-md-4 p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    /* RB text */
    color: #333333;
}
.new-user-plans {
    margin-top: 3.688rem ;
    font-family: "Circular Std Book", sans-serif;
}
.new-user-plans ul {
    list-style-type: none;
    padding-left: 0;
}
.new-user-plans ul li {
    font-size: 16px !important;
    font-family: "Circular Std Book", sans-serif !important;
    color: #393939 !important;
    margin-top: .5rem;
}
.plantype {
    background-color: black;
    color: white;
    padding: 8px 13px;
    font-size: 12px;
    font-family: "Circular Std Book", sans-serif;
    font-weight: 500;
    border-radius: 200px;
    text-align: center;
}
.plantype-black {
    background-color: white;
    color: black;
    padding: 8px 13px;
    font-size: 12px;
    font-family: "Circular Std Book", sans-serif;
    font-weight: 500;
    border-radius: 200px;
    text-align: center;
}
.new-user-plans ul img {
    margin-right: 1rem;
}
.light-card{
    padding: 1.5rem;
    background-color: white;
}
.dark-card {
    padding: 1.5rem;
}
.light-card p {
    color: #393939 !important;
    font-size: 14px !important;
    font-weight: 400 !important;

}
.light-card h5 {
    font-size: 25px;
    font-weight: 700;
    font-family: "Circular Std Book", sans-serif;
}
.light-card h6{
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 46px;
    color: #000000;
    margin-top: 1rem;
    font-family: "Circular Std Book", sans-serif;

}
.light-card span {
    font-family: 'Circular Std Book', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    vertical-align: super;
}
.dark-card h6 {
    font-family: 'Circular Std Book', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 46px;
    color: #FFFFFF;


}
.dark-card span {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: #FFFFFF;
    vertical-align: super;
}
.dark-card {
    background: #000000;
    border-radius: 12px;
    padding-top: 1.25rem;
    padding-right: 1.875rem;
    padding-left: 1.875rem;
}
.light-card {

    border-radius: 12px;
    padding-right: 1.875rem;
}
.light-card hr {
    height: 2px;
    background-color: #E4E4E4;
    color: #E4E4E4;
    border: none;
    margin-top: 1rem;
}
.dark-card hr {
    height: 2px;
    background-color: #E4E4E4;
    color: #E4E4E4;
    border: none;
    margin-top: 1rem;
}
.dark-card button, .light-card button {
    border-radius: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    color: #000000;
    margin-bottom: 1.25rem;
    width: 100%;
    margin-top: 1.438rem;
}
.dark-card ul li {
    color: white !important;
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
}
.new-user-plans li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    background-image: url('../../../../assets/light-circle.svg');
    background-size: cover;
    background-repeat: no-repeat;
}
.dark-card h5 {
    color: white;
    font-size: 25px;
    font-weight: 700;
    font-family: "Circular Std Book", sans-serif;
}
.dark-card button {
    background: #FFFFFF;
}
.light-card button {
    background: #BBBBBB;
}
.popular-plan {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.833333px;
    color: #000000;
    background: #E3DDFF;
    border-radius: 13.5px;
}
.dark-card p {
    font-family: 'Circular Std Book', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}
.start {
    margin-top: 1rem;
    margin-bottom: 10.375rem;
}
.start button {
    background: #211667;
    border-radius: 24px;
    color: white;
    padding: 0.563rem 1.375rem;
}
.start button:hover {
    background: white;
    border: 1px solid #211667;
    color: #211667;
    transition: .5s ease-in-out;
}
.dark-card .popular-plan {
    margin-top: 1.25rem;
}
.btn-get-started{
    background: #130071;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08) ;
    border-radius: 100px;
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF;
    width: 100%;


}
.btn-get-started:hover {
    border: 1px solid #130071;
    color: #130071;
    background: white;
    transition: .5s ease-in-out;
}



