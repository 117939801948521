.skeleton {
    background: #ddd;
    margin: 10px 0;
    border-radius: 4px;
}
.text {
    width: 100%;
    height: 12px;
}
.title{
    width: 90%;
    height: 20px !important;
    margin-bottom: 15px;
}
 .image {
    width: 100%;
    height: 150px;
}
 .skeleton-wrapper {
     margin-top: 50px !important;

 }