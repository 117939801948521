.events-title {
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    text-align: center;
    color: #070707;
}
.events-date{
    font-weight: 600;
    font-size: 25px;
    line-height: 31px;
    text-align: center;
    color: #333333;
}
.events-item-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 124.52%;
    color: #333333;
}
.events-item-host {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #767676;
}
.events-item-description {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 134.5%;
    color: #767676;
}
.events-register {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #522FFF;
}
.upcoming {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #BBBBBB;
    line-height: 0.1em;
    margin: 10px 0 20px;
}

.upcoming span {
    background:#fff;
    padding:0 10px;
}
.mobile .events-item-title {
    margin-top: 0.563rem;
}
.events img {
    border-radius: 5px;
}