/*------Header-----*/
@font-face {
    font-family: "Circular Std Book";
    src: url("FontsFree-Net-Circular-Std-Book.ttf");
}
.desk-menu-item:hover {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    background-color: #EEEEEE;
}
.desk-menu-item {
    background-color: white;
    padding: 1px 8px;
    margin-top: 5px;
    height: 2.5rem;
}
.desk-menu-item-active {
    background:#F3F3F3;
    padding: 1px 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    height: 2.5rem;
}
.menu-items .desk-menu-item-active {
    background: #EFECFF;
    padding: 10px 8px;
    border-radius: 4px;
    height: 100%;
    display: flex;
    align-items: center;
}
.desk-menu-item-active h6 {
    color: #333333 !important;
}
.nav-link:hover{
    font-weight: 600;
    text-decoration: underline;
    transition: .5s ease-in-out;
}

header {
    margin-top: 1rem;
    padding: 0 2rem;
}
.login-banner {
    display: none;
}
.userprof .dropdown-toggle::after {
    display: none;
}
.col-md-10 a {
    text-decoration: none ;
}
.col-md-8 a {
    text-decoration: none ;
}
.form-control:focus{
    outline: none !important;
    box-shadow: none;
}
.col-10 a {
    text-decoration: none ;
}
.logbtndesk {
    display: none;
}
.navbar-brand img {
    width: 100px;
    height: auto;
}
.btn-login {
    width: 91px;
    height: 42px;
    border: .5px solid #333333 ;
    background: white;
    border-radius: 4px;
    font-size: 14px;
    line-height: 17px;
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 600;

    /* RB text */
    color: #333333;
}
.btn-login:hover {
    width: 91px;
    height: 42px;
    background: #070707;
    border-radius: 4px;
    color: white !important;
    font-size: 14px;
    line-height: 17px;
    transition: .5s ease-out;
}

.logo {
    height: 60px !important;
    width: 60px !important;
    display: flex;
    align-items: center;
}
.nav-logo img {
    height: 60px !important;
    width: 60px !important;
    display: flex;
    align-items: center;
}
.nav-link {
    padding: 0 .5rem !important;
}
.navbar-brand {
    padding-left: 3.5rem;
}
.logo img{
    height: 60px;
    width: 60px;
}
 /*****USER DROPDOWN MENU******/
.navbar-expand-lg .navbar-nav .dropdown-menu {
    left: unset !important;
    right: 0;
    width: 13.75rem;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 10px;
    box-shadow: 0.5px 6px 59px rgba(52, 64, 84, 0.12);
    margin-top: 1rem;
}
.calendar-container {
    left: unset !important;
    right: 0;
    width: 350px;
    padding-left: 1% !important;
    padding-right: 1% !important;
    box-shadow: 0px 29px 80px rgba(0, 0, 0, 0.36);
    border-radius: 6px;
    padding-top: 25px;
}
.mobile .calendar-container {
    left: unset !important;
    right: 0;
    width: 100%;
    padding-left: 1% !important;
    padding-right: 1% !important;
    box-shadow: 0px 29px 80px rgba(0, 0, 0, 0.36);
    border-radius: 6px;
    padding-top: 25px;
}
.calendar-container p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #00944D;
}
.calendar-container .dropdown-menu h6 {
    font-family: "Kumbh Sans", sans-serif;
    font-size: 14px !important;
    font-weight: 300;
    letter-spacing: 0em;
    text-align: left !important;
    padding: 0 !important;

}
.menu-items h6 {
    margin-top:4%;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 15px;
    text-align: center !important;
    padding: 0;
    color: #333333;
}
.nav-menu-items h6 {
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 15px;
    text-align: center !important;
    padding: 0;
    color: #333333;
}
.nav-menu-items span {
    font-size: 20px;
    color:  #0d004c !important;
}
.nav-item-icon {
    padding: 10%;
    font-size:40px;
}
.nav-menu-items .col-md-4 {
    text-align: center !important ;
    padding: 7px;
}
/*-----Footer-------*/
footer {
    background: #F9FAFB;
    padding: 4rem 7rem 1rem 7rem !important;
}
footer h6{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}
.footer-logo {
    width: 130px !important;
}
footer ul{
    list-style-type: none;
    padding: 0;
    overflow: hidden;
    margin-top: 2rem;
}
footer li{
    display: block;
    float: left;
    margin-right: 2rem;
}
footer li a{
    text-decoration: none;
    color: #333333;
}
footer span {
    margin-left: 2rem;
    font-size: 2rem;
    display: flex !important;
    flex-direction: row;
}
.tablet footer span {
    margin-left: 2rem;
    font-size: 1.5rem;
    display:flex;
    flex-direction: column !important;
}
/*----Footer Tablet-----*/
footer {
    background: #F9FAFB;
    padding: 4rem 2rem 1rem 2rem !important;
}
/*----Footer Mobile-----*/
.mobile footer {
    background: #F9FAFB;
    padding: 2rem  !important;
}
.mobile footer h6{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}
.mobile footer img {

}
.mobile footer ul{
    list-style-type: none;
    padding: 0;
    overflow: hidden;
    margin-top: 2rem;
}
.mobile  footer li{
    display: inline-block;
    width: 100%;
    margin-top: 1rem;
}
.mobile  footer li a{
    text-decoration: none;
    color: #333333;
}
.mobile footer span {
    margin-left: 2rem;
    font-size: 30px;
}
/*-----Sidebar-------*/
.menu-items {
    padding-left: 1rem;
}
.sidebar {
    height: 90vh;
    position: fixed;
    background: white;
    z-index: 1000;
    left: 0;
    padding-right: 1rem;
    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    transition: all 0.45s ease-out;
    overflow-y: scroll;
}
.sidebar .menu-items .col-4{
    margin-top: 1rem;
}
.sidebar .close-icon {
    margin-left: 10px;
    background: #EBEBEB;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #767676;
    cursor: pointer;
}
.inactiveside {
    width: 70px;
    height: 100%;
    position: fixed;
    background: #FAFAFA;
    transition: width .2s ease-in;
    display: none;
}
.inactiveside .close-icon {
    margin-left: 10px;
    background: #EBEBEB;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #767676;
    cursor: pointer;
}
.sidebar .menu-items {

}
.inactiveside .menu-items {
    margin-top: 50px;
}
.inactiveside .menu-items ul {
    list-style-type: none;
    margin-left: 0 !important;
    padding-left: 0 !important;
}
.sidebar .menu-items ul {
    list-style-type: none;
    margin-left: 0 !important;
    padding-left: 0 !important;
}
.inactiveside .menu-items ul a {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Kumbh Sans', sans-serif !important;

}
.inactiveside .menu-items li:hover {
    border-left: 4px solid #070707;
    transition: ease-in-out .4s;

}
.menu-items ul a {
    height: 50px;
    display: flex;
    align-items: center;
    font-family: 'Kumbh Sans', sans-serif !important;
    text-decoration: none;
    color: black;
    margin-left: 0;
    padding-left: 0;
    width: 100%;

}
.sidebar .menu-items ul li {
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 10%;
    font-family: 'Kumbh Sans', sans-serif !important;

}
.sidebar .menu-items ul li span{
    margin-left: 5%;

}
.sidebar .menu-items .active {
    background: #070707;
    border-radius: 0px 10px 10px 0px;
}
.sidebar .menu-items li:hover > a {
    background: #070707;
    border-radius: 0px 10px 10px 0px;
    color: #fdfdfd;
    padding-left: 10%;
}
.sidebar .menu-items li:hover {
    padding-left: 0 ;
    transition: .2s ease-in-out;
    background: #070707;
}
.sidebar .menu-items .active a{
    font-size: 14px;
    color: white !important;
}
.sidebar .menu-items {
    font-size: 14px;
}
.inactiveside .menu-items ul li span{
    display: none;

}
.inactiveside .menu-items .active {
    border-left: 4px solid #070707;
    border-radius: 0px 10px 10px 0px;
}
.inactiveside .menu-items .active {
    font-size: 14px;
    color: black !important;
}
/*--------Search Field------*/
.main {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 14.063rem;
    padding-right: 14.063rem;
    margin-top: 5.563rem;
}
.main1 {
    min-height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tooltop1 {
    padding-left: 0rem;
    padding-right: 0rem;
}
.mobile .tooltop1 {
    padding-left: 1rem;
    padding-right: 0rem;
}
.prof-img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 1px solid silver;
}
.prof-img-profile {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 1px solid silver;
}
.prof-img1 {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 1px solid silver;
    padding: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.prof-icon {
    height: 25px;
    width: 25px;
    z-index: 10000;
}
.prof-name {
    overflow: hidden;
}
.dropdown-menu {
    min-width: 8rem;
    margin-top: 60px;
    border-radius: 4px;
    border: .5px solid #EBEBEB;
    box-shadow: 0px 4px 6px 0px #522FFF08;

}
.dropdown-menu ::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.filters-drop {
    height: fit-content !important;
}
.filter-level1 ul {
    height: 50px !important;
}
.dropdown-menu ul {
    list-style-type: none;
    padding-left: 0 !important;
}
.dropdown-menu h6 {
    font-family: "Circular Std Book", sans-serif;
    font-size: 14px !important;
    font-weight: 300;
    letter-spacing: 0em;
    text-align: center !important;
    margin-bottom: 0;
}
.dropdown-menu img {
    width: 1.25rem;
    height: 1.25rem;
}
.dropdown-menu li a {
    font-family: "Kumbh Sans", sans-serif;
    font-size: 16px !important;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left !important;
    padding: .25rem 1rem;

}
.filtermobile .dropdown-menu li a {
    font-family: "Kumbh Sans", sans-serif;
    font-size: 12px !important;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left !important;
    padding: .25rem 1rem;

}
.dropdown-menu li a:hover {
    background: #EBEBEB !important;
}
.welcome-message h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 3.125rem;
    text-align: center;
    color: #070707;
    margin-top: 3rem;


}
.mobile .welcome-message h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 3.125rem;
    text-align: center;
    color: #070707;
    margin-top: 3rem;


}
.nav-buttons .btn {
    width: 54px;
    height: 50px;
    color: white;
    background: #070707;
    border-radius: 9px;
}
.welcome-section h3 {
    font-weight: bold !important;
    font-size: 50px;
}

.welcome-message {
    padding: .688rem 10rem;
    text-align: center;
    display: flex;
    justify-content: center;

}
.tablet .welcome-message {
    padding: .688rem 2rem;
    text-align: center;
    display: flex;
    justify-content: center;

}
.mobile .welcome-message {
    padding: 0;
    margin-top: 3rem;
}
.welcome-message p {
    font-weight: 500;
    line-height: 32px;
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-size: 25px;
}
.mobile .welcome-message p {
    font-weight: 500;
    line-height: 32px;
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-size: 20px;
}
.search-section{
    background-color: white;
    border-radius: 19px;
}
.mobile .search-section{
    background-color: white;
    border-radius: 19px;
    padding: 0 1rem;
}
.tablet .search-section1{
    background-color: #ffffff;
    padding-left: 1rem;
    padding-bottom: 3.5rem;
    padding-right: 1rem;
    margin: 0;
}
.mobile .search-section1{
    background-color:  #ffffff;
    width: 100%;
    border-radius: 0;
    margin-top: 0;
    padding: 0;
}
.video-container video {
    height: 70vh !important;
}
.video-container div {
    height: unset !important;
    width: unset !important;
}
.marquee-container .overlay {
    --gradient-color: unset !important;
    --gradient-width: unset !important
}
.profile-update-modal .modal-content {
    width: 45rem !important;
    border-radius: 6px !important;
    padding: 1rem 1rem 1.5rem 1rem !important;
    position: absolute;
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.profile-update-modal-mobile .modal-content {

    border-radius: 6px !important;
    padding: 1rem 1rem 1.5rem 1rem !important;
    position: absolute;
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.profile-update-modal h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;

    /* Black */
    color: #333333;
    margin-top: 2.75rem;


}
.profile-update-modal-mobile h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;

    /* Black */
    color: #333333;
    margin-top: 2.75rem;


}
.profile-update-modal h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    /* Black */
    color: #333333;
    text-align: center;
    margin-top: 1rem;


}
.profile-update-modal-mobile h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    /* Black */
    color: #333333;
    text-align: center;
    margin-top: 1rem;


}
.profile-update-modal button {
    background: #130071;
    border-radius: 6px;
    padding: 1.063rem 2.75rem;
    color: white;
    width: 100%;
    margin-top: 2.938rem;
}
.profile-update-modal button:hover {
    background: white;
    color: #130071;
    transition: .7s all;
    border: 1px solid #130071;

}
.profile-update-modal .form-control {
    height: 3rem;
    border-radius: 6px;
}
.profile-update-modal-mobile button {
    background: #130071;
    border-radius: 6px;
    padding: .563rem .75rem;
    color: white;
    width: 100%;
    margin-top: 1.938rem;
}
.search-section1{
    padding-bottom: 3.5rem;
    padding-left: 6.375rem;
    padding-right: 6.375rem;
}
.search-section1 h1 {
    font-size: 35px;
    line-height: 140%;
    text-align: center;
    font-family: "Circular Std Book", sans-serif;
}
.notion-tag-item {
    color: #CECECE;
    padding: 8px 15px;
    width: fit-content;
}
.tablet .notion-tag-item {
    color: #CECECE;
    padding: 8px 15px;
    width: fit-content;
}
#basic-addon1 {
    background-color: white;
}
.notion-tags {
    margin-top: .35rem;
    justify-content: center;
}
.mobile .notion-tags {
    justify-content: center;
}
.notion-tags a {
    color: #CECECE;
    cursor: pointer;
    text-decoration: none;
}
.notion-tags a:hover{
    transition: ease-in-out .2s;
    color: rgb(140, 117, 255);
    text-decoration: underline;
    font-weight: 500;
}
.tablet .notion-tags a {
    color: #CECECE;
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
    width: 100%;
    text-align: center;
}
.mobile .notion-tags a {
    color: #CECECE;
    padding: 8px 10px;
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
    width: 100%;
    text-align: center;
}
/*.notion-tags span{
    color: #CECECE;
    border: 1px solid #CECECE;
    border-radius: 1.188rem;
    padding: 8px 15px;
    margin-right: 1rem;
    cursor: pointer;
    text-decoration: none;
}*/
.mobile .notion-tags span{
    color: #CECECE;
    margin-right: 1rem;
    cursor: pointer;
    text-decoration: none;
    font-size: 12px;
}
.search-field{
    border: 1px solid silver;
    border-radius: 30px;
    padding-left: 3%;
    padding-right: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    color: silver;
}
    /*------Suggestions as user types-----*/
.form-input1 {
    background: white;
    box-shadow: 0 4px 6px rgba(32,33,36,.28);
    border-radius: 5px 0px 10px 10px;
    width: 100%;

}
.suggest {
    background: white !important;
    z-index: 1000 !important;
}
.suggest-option{
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
}
.rb {
    color:  #00944D !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
}
 .rb span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    /* Dark grey */
    color: #767676 !important;


}
.suggest-option:hover{
    background: rgb(13, 0, 76, .03);
    margin: 0;
}
    /*------Suggestions as user types end-----*/
.gsc-search-button {
    display: none !important;
}
.gsc-input {

    background: none !important ;
    height: 30px;
}
#___gcse_0{
    width: 95% !important;
    padding-top: .5%;
    padding-bottom: .5%;
}
.gsc-input-box {
    border: none !important;
}

.search-field-text {
    border: 1px solid #cecece !important;
    border-radius: 100px 0px 0px 100px;
    font-family: "Circular Std Book", sans-serif;
    height: 3.8rem;
    padding-left: 4%;
    font-size:  0.875rem;
    font-weight: 400;
    width: 100%;
}
.mobile .search-field-text {
    border-radius: 100px 0px 0px 100px;
    /*box-shadow: 0 2px 5px 1px rgba(64,60,67,.16);*/
    padding-left: 1.563rem;
    font-size:  0.875rem;
    font-weight: 400;
}
.mobile .searchb {
    border-radius: 300px;
}
.searchb {
    background-image: url("../../../assets/Stars.svg");
}
.mobile .input-group-text {
    border-radius: 0 300px 300px 0 ;
    background-color: white;
    border: none;
    padding: .375rem 1.75rem;
    font-size: 20px;
}

.search-field-text1 {
    border: none;
    border-radius: 6px;
    /*box-shadow: 0 2px 5px 1px rgba(64,60,67,.16);*/
    height: 4.25rem;
    font-size:  0.875rem;
    font-weight: 400;
    outline: none;
}
.mobile .search-field-text1 {
    border-radius: 6px;
    /*box-shadow: 0 2px 5px 1px rgba(64,60,67,.16);*/
    height: 3rem;
    padding-left: 2% !important;
    font-size:  0.875rem;
    font-weight: 400;
}
.tablet .side-filters h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 20px;
    color: #070707;
}
.tablet .side-filters h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 20px;
    color: #070707;
}
.tablet .side-filters .container-fluid {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.tablet .side-filters {
    padding-top: 0rem;
    padding-right: 1rem;
    padding-left: 1rem;
}
.tablet .search-field-text1 {
    border-radius: 6px;
    /*box-shadow: 0 2px 5px 1px rgba(64,60,67,.16);*/
    height: 4rem;
    padding-left: 2% !important;
    font-size:  0.875rem;
    font-weight: 400;
}
.search-field-text1 {
    /*box-shadow: 0 2px 5px 1px rgba(64,60,67,.16);*/
    height: 3.5rem;
    font-size:  0.875rem;
    font-weight: 400;
    z-index: 100 !important;
    outline: none !important;
}
.mobile .search-field-text1:focus {
    outline: none !important;
    border: none !important;
}
textarea:focus, input:focus{
    outline: none;
}
.side-filters .container-fluid {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.search-field-text:focus {
    outline: none;
}
.input-group-addon h5 {
    color: gray;
}
.form-input {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
}
.mobile .form-input {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 3px 4px;
}
.btn-search {
    width: 100%;
    height:3.8rem;
    /* RB black main */
    background: #070707;
    border-radius: 0px 100px 100px 0px !important;
    color: white !important;
    font-size: 1.125rem;
    line-height: 17px;
    font-weight: 600;
    cursor: pointer !important;
}
.btn-search:hover {
    width: 100%;
    height: 3.8rem;
    /* RB black main */
    background: white;
    border: .5px #070707 solid;
    border-radius: 4px;
    color: #070707 !important;
    font-size: 1.125rem;
    line-height: 17px;
    font-weight: 600;
    transition: .5s ease-in-out;
}
.search-tags h6{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}
.search-tags {
    padding: 2.5rem 0rem 0rem 0rem;
}
.search-tags p:hover{
    transition: ease-in-out .2s;
    color: rgb(82, 47, 255);
    text-decoration: underline;
    font-weight: bold;

}
.categories-morelinks {
    display: flex;
    align-items: center;

}
.categories-morelinks a {
    text-decoration: none;

}
.categories-morelinks h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #522FFF;
    float: left;
    margin-bottom: 0;

}
.mobile .categories-morelinks h6 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 17px;
    color: #522FFF;
    text-decoration: none;

}
.categories-morelinks span {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 1.5px solid #200E32;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    margin-left: 14.38px;
    color: #200E32;
    font-size: 30px;
}
.mobile .categories-morelinks span {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 1.02128px solid rgba(32, 14, 50, .4);
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    margin-left: 14.38px;
    color: #200E32;
    font-size: 30px;
}
.tags-resources {
    margin-top: 4.438rem;
}
.tags-resources img:hover {
    transition: .3s ease-in-out;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
    transform: translate(10px, -10px);
}
.tags-resources .col-md-4 .tags-border {
    border: 1.2px solid #E9E5FF;
    border-radius: 6px;
}
.join-our-community .btn:hover {
    box-shadow: inset 15em 0 0 0 #000000;
    color: white;
    transition: .7s all;
}
.join-our-community img {
    animation: swing ease-in-out 1s infinite alternate;
    transform-origin: center -20px;
    float:left;
}
@keyframes swing {
    0% { transform: rotate(3deg); }
    100% { transform: rotate(-3deg); }
}
.join-our-community .btn {
    color: #000000;
    background: white;
}
.search-tags a {align-items: center;
    border-radius: 12px;
    color: #767676;
    display: flex;
    float: left;
    font-size: 16px;
    font-weight: 500;
    height: 2.75rem;
    justify-content: center;
    margin-left: auto;
    margin-right: 2%;
    text-decoration: none !important;
    width: fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.calendar-icon {
    width: 21px;
    height: 21px;
}
.search-tags a:first-child {
    margin-top: 0;
}
.search-tags a:first-letter{
    text-transform: uppercase !important;
}
.search-tags a {
    margin-top: 1.438rem;

}
.navbar-light .navbar-toggler {
    border:none !important;
    color: #757D8A;
}
    /*.search-tags a {
        text-decoration: none !important;
        color: #767676;
        font-size: 14px;
        margin-right: 2%;
        font-weight: 500;
        border-radius: 12px;
        height: 2.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        float: left;
        margin-left: auto;
    }*/
.search-tags button{
    border: 1px solid gray;
    color: gray;
    border-radius: 5px;
    margin: 2%;
    font-size: 14px;
}
.search-icon {
    position: absolute;
    top: 0;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

/*Resources section on the landing pages */
.categories .card h6 {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    display: inline-block;
}
.categories-content img {
    display: inline-block;
}
.categories .card p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px
}
.categories .row2 {
    margin-top: 1rem;
}
.categories-content .btn {
    font-size: 10px;
    height: 2rem;
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
}
.categories .card {
    box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
}
.categories-content a {
    text-decoration: none;
}
.links .bg-custom {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
}
.links .badge {
    padding: 0;
}
.links .col-md-3, .links .col-md-5 {
    border: 1px solid rgba(118, 118, 118, .15);
    border-radius: 13px;
    padding: 1% 2% 1% 2%;
    background: #fdfdfd;
}
.mobile .links .col-md-3, .mobile .links .col-md-5 {
    border: 1px solid rgba(118, 118, 118, .15);
    border-radius: 8px;
    padding: 1% 2% 1% 4% !important;
    background: #fdfdfd;
}
.links-span {
    font-weight: 600;
    font-size: 20px;
}
.categ-header {
    padding-top: 3rem;
}
.mobile .categ-header {
    padding-top: 0rem;
}
.categories .bg-custom{
    background: #FCFCFF !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #0D004C;
    height: 29px;
    display: inline-block;
    align-items: center;
    padding-top: 1.2%;
}
.mobile .categories .bg-custom{
    color: #0D004C;
    height: 29px;
    align-items: center;
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    background: #F2EFFF !important;
    border-radius: 6px;
    padding: 6px 15px !important;
}
.bg-custom1{
    color: #0D004C !important;
    background: #F2EFFF !important;
    font-size: 24px;
    height: 56px;
    width: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mobile .categories-content {
    margin-top:2rem;
}
.categories-content {
    margin-top: .875rem;
}
.categories-content-main {
    margin-top: .875rem;
}
.categories-content-main h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    font-family: "Circular Std Book", sans-serif;
    color: #000000;
}
.categories-content-main p {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    /* RB black */
    color: #7E7E7E;
    font-family: "Circular Std Book", sans-serif;

}
.categories-content-main a {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;

    /* RB active/RB success */
    color: #000000;
    text-decoration: none;


}
.community-container {
   height: 17.188rem;
    border-radius: 1.25rem;
    background-image: url("../../../assets/Stars.svg");
}
.community-container img {
   /* height: 7rem;*/
    border-radius:1.25rem 1.25rem 0 0 ;
    width: 100%;
}
.community-container h6 {
    font-family: "Circular Std Book", sans-serif !important;
    color: black !important;
    font-weight: 500 !important;
    font-size: 16px !important;
}
.community-container:hover{
     transition: .3s ease-in-out;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
    border-radius: 8px;

}
.community-container button {
    background-color: black;
    height: 2.75rem;
    border-radius: 200px;
    font-family: "Circular Std Book", sans-serif;
    color: white;
    font-size:1rem;
    padding: 12px 26px !important;
    border: none;
    display: flex;
    align-items: center;
    margin-top: 2rem;
}
.searchb .categories-item:hover{
    transition: .3s ease-in-out;
    box-shadow: none;
}

.searchb:hover{
    transition: .3s ease-in-out;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
}
.categories .card:hover{
    transition: .3s ease-in-out;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
}
.landing h6 {
    text-align: left !important;
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #070707;
}
.categories-content .card h6 {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.395rem;
    text-align: center;
    color: #070707;
}
.searchb img {
    width: 1.25rem;
    height: 1.25rem;
}
.searchb .categories-content h6 {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    line-height: 120%;
    font-family: "Circular Std Book", sans-serif;
    font-style: normal;
    text-align: center;
    display: inline-block;
    margin-left: 0.625rem;
}


.categories .card p {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 134.52%;
    color: #767676;
    text-decoration: none;
    margin-top: 0.875rem;
}
.user-icon {
    height:2rem;
    width:2rem;
    border-radius: 50%;
}
.searchb .categories-content p {
    font-style: normal;
    font-weight: 400;
    color: #767676;
    font-size: 11px;
    margin: 0px;
    line-height: 150%;
    font-family: "Circular Std Book", sans-serif;
}

.mobile .categories-content p {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}
.categories-content .btn {
    background: #070707;
    border-radius: 6px;
    color: #FFFFFF;
    height: 52px;
    margin-top: 2rem;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    padding: 0.938rem 0.813rem 0.938rem 1.188rem;
}
.categories-content .btn:hover {
    background: white;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #070707;
    height: 52px;
    margin-top: 2rem;
    border: #070707 .5px solid;
    transition: .5s ease-in-out;
}
.categories .row2 {
    /*margin-top: 7.813rem;
    margin-bottom: 7.375rem;*/
}
.row3{

}
.row4{
    margin-top: 8rem;
}
.categories-row {
    margin-top: 8%;
}
.mobile .categ-header {
    margin-top: 4rem;
}
.featured {
    box-shadow:0 6px 7px rgba(0, 0, 0, 0.5);

}
.categories {
    margin-top: 5rem;
    border-top: 1px solid #E4E4E4;
}
.mobile .categories {
    margin-top: 0;
}
.categories h6 {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    line-height: 120%;
    margin-top: 2.5rem;
}
.special-collections {
    margin-top: 5rem;
    border-top: 1px solid #E4E4E4;
}
.special-collections h6 {
    font-size: 18px;
    font-weight: 500;
    color: #777777;
    line-height: 120%;
    margin-top: 2.5rem;
}
.categories .card{
    padding: 1.875rem 1.438rem 1.438rem 1.875rem;
    background: white;
}


.categories .explore {
    color: black;
    margin-bottom: 1.438rem;
    font-size: 12px;

}
.categories .explore img {
    margin-right: 0.625rem;
}
.categories .explore span {
    height: 1.375rem;
    width: 1.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.categories .container {
    border-radius:7.18px;
    background-image: url("../../../assets/Stars.svg");
    height: 12rem !important;
}
.stages {
    background-image: url("../../../assets/stagesbg.svg");
    height: 16.063rem;
    border-radius: 1.25rem;
    margin-top: 5rem;
    padding-top: 2.5rem;
    background-size: cover;
    background-repeat: no-repeat;
}
.tablet .stages {
    height:auto;
    padding: 1rem 2rem;
}
.mobile .stages {
    height:auto;
    padding: 1rem 2rem;
}
.tablet .stages {
    height:auto;
    padding: 2rem;
}
.stages-items h1 {
    font-family: "Circular Std Book", sans-serif;
    color: black;
    font-size: 16px;
    display: inline-block;
}
.stages-items img {
    height: 35px;
    width: 35px;
    float: left;
    margin-right: 1rem;
}
.stages h6 {
    font-size: 18px;
    color: white;
    font-weight: 500;
    line-height: 120%;
}
.stages-items .container {
    height: 5.313rem;
    border-radius: 8px;
    background-color: white;
    display: flex;
    align-items: center;
}
.categories .container:hover {
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}
.mobile .categories {
    border: none;
    border-radius: 6px;
    padding: 3.375rem 0.875rem 4.313rem 1rem;
}

.mobile .categories {
    padding: 1.0rem;
}
.mobile .row2{
    margin-top: 0 !important;
}
/*----Resources section on the landing pages mobile css-----*/
.mobile .categories-content h6 {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: bolder;
    font-size: 16px;
    line-height: 20px;
}
.mobile .categories-content p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-decoration: none !important;
}
.mobile .categories .row2 {
    margin-top: 1rem;
}
.categories a {
    text-decoration: none;
}
.mobile .categories-content .btn {
    font-size: 14px;
    height: 2.5rem;
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
}
.mobile .categories-content a {
    text-decoration: none;
}
/*------Search Area Mobile CSS------*/
.mobile .welcome-message h3{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #070707;
    margin-right: 0rem;
    margin-left: 0rem;
}
.mobile-nav {
    padding-left: 0 !important;
}
.mobile .main {
    margin-top: 0.125rem;
}


.mobile .btn-search {
    /* Search Button */
    height: 3.625rem;
    background: #070707;
    border-radius: 0px 6px 6px 0px;
    color: white !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer !important;
    width: fit-content;
}
.mobile .btn-search:hover {
    height: 3.625rem;
    background: white;
    border: .5px #070707 solid;
    border-radius: 4px;
    color: #070707 !important;
    font-size: 1.125rem;
    line-height: 17px;
    font-weight: 600;
    cursor: pointer;
}
.mobile .search-field-text {
    /*Input field for the search section */
    height: 3.625rem;
    border-width: 1.2px 0px 1.2px 1.2px;
    border-style: solid;
    border-color: #E9E5FF;
    border-radius: 100px 0px 0px 100px;;
}
/*------Search Area Tablet CSS------*/
.tablet .welcome-message h3{
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
    text-align: center;
    color: #070707;
}
.mobile-nav {
    padding-left: 0 !important;
}
.tablet .main {
    margin-top:0rem;
}
.links {
   /* margin-top: 5rem;*/
}
.hidden-mob{
    display: none;
}
.mobile .links-span {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
}
.mobile .links  .bg-custom1{
    color: #0D004C !important;
    background: #F2EFFF !important;
    font-size: 13px;
    height: 1.625rem;
    width: 1.625rem;
    display: flex;
    align-items: center;
    justify-content: center;

}

/*---Calendar-------*/
.calendar h6{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
}
.calendar {
    margin-top: 4.688rem !important;
}
.time {
    font-family: 'Inter', sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 16px !important;
    color: #767676 !important;
}
.title {
    font-family: 'Inter', sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;

    /* identical to box height */

    /* RB text */
    color: #333333 !important;
}
.subscribe {
    background: #FFE6E6;
    border-radius: 4px;
    padding: 15px 24px 18px 16px;
    margin-bottom: 27px;
}
.subscribe p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: #333333;
}
.btn-upgrade {
    background: #070707;
    border-radius: 4px;
    height: 28px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #FFFFFF !important;
    width: 100%;


}
.btn-upgrade-later {
    background: inherit;
    border-radius: 4px;
    height: 28px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #333333;
    border: 1px solid #333333;
    width: 100%;
}
.calendar-open {
    font-size: 18px;
    color: #0D004C;
}
.accordion-button{
    display: inline-block !important;
}
.accordion-item{
    border: none;
}
.calendar .title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
}
.calendar .location {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #00944D;
}
.accordion-button:not(.collapsed) {
    background: white !important;
    box-shadow: none;
}

.accordion-button {
    padding: 0;
    margin-top: 0rem;
}

/*-----Discover and workspace buttons at the top-----*/
.btn-discover {
    border: 0.5px solid #070707;
    border-radius: 8px;
    background-color: white;
    color: #070707;
    padding: 13px 31px;

}
.btn-workspace {
    background: #070707;
    border-radius: 8px;
    color: white;
    margin-left: 24px;
    padding: 13px 31px;
}

/*-------Participate in research------*/
.btn-participate {
    background: #070707;
    border-radius: 6px;
    color: #FFFFFF;
    height: 52px;
    margin-top: 2rem;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    padding: 0.938rem 0.813rem 0.938rem 1.188rem;

}
.participate .form-control {
    margin-top: 9px;
    height: 55px;
}
.participate label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16.5708px;
    line-height: 20px;

    /* identical to box height */

    /* RB text */
    color: #333333;
}
.calendly-inline-widget {
    height: 850px;
    min-height: 620px;
}
._cUP1np9gMvFQrcPftuf {
    display: flex;
    justify-content: start !important;
    margin-top: 0 !important;
}
.carousel-control-next {
    right: 0;
    background: white;
    height: 30px;
    margin-top: 10%;
    border-radius: 50%;
    opacity: 1;
    color: black !important;
    width: 30px;
    padding: 2%;
    border: 1px solid #F4F4F4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}
.carousel-control-prev {
    left: 0;
    background: white;
    height: 30px;
    margin-top: 10%;
    border-radius: 50%;
    opacity: 1;
    color: black !important;
    width: 30px;
    padding: 2%;
    border: 1px solid #F4F4F4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}
.carousel-control-next-icon {
    background-image: url("../../../assets/Stroke 31.png") !important;
}
.carousel-control-prev-icon {
    background-image: url("../../../assets/Stroke 3.png") !important;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 40% 40%;
}