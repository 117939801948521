/*----Styles for Search results------*/
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700&family=Poppins:ital,wght@0,400;0,500;0,600;1,400&display=swap');
.filter-level2 li{
    float: left;

}
.summary-title {
    font-family: 'Heebo', sans-serif !important;
}
.summary {
    font-family: 'Heebo', sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 22px !important;
    list-style-type: none !important;

    /* or 138% */

    color: #000000;
}
.generating {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}
.dropdown-menu li a:hover{
    background: white !important;
}
.result-component {
    margin-bottom: 1.565rem;
}
.mobile .result-component {
    border-bottom: 1px solid #EFEFEF;
    border-top: 1px solid #EFEFEF;
    margin-bottom: 0.5rem;
    padding:1.563rem 1.5rem 1.25rem 1.5rem;
}
.first-result-component {
    margin-bottom: 0.5rem;
}
.mobile .first-result-component {
    border-bottom: 1px solid #EFEFEF;
    margin-bottom: 0.5rem;
    padding:0 1.5rem 1.25rem 1.5rem;
}
.results h6 {
    color: #767676;
    font-size: 12px;
    font-weight: 400;
    text-decoration: none;
}
.result-url {
    color: #522FFF;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    cursor: pointer;

}
.mobile .result-url {
    color: #522FFF;
    font-size: 12px;
    font-weight: 400;
    text-decoration: none;
    overflow: hidden;
    line-height: 15px;
}
.result-name {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #00944D;
    text-transform:capitalize !important;
    text-decoration: none !important;
    cursor: pointer;
}

.result-section .result-name {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 20px !important;

    /* RB active/RB success */
    color: #00944D;
    margin-top: 1rem;
}
.result-section .result-snippet {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #333333;


}
.mobile .result-snippet {
    color: #333333;
    font-size: 14px;
    margin-bottom: 0 !important;
}
.result-snippet {
    color: #333333;
    font-size: 14px;
    margin-bottom: 0 !important;
}
.result-snippet:first-letter {
    text-transform: capitalize !important;
}
.date-crawled {
    font-family: 'Kumbh Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #767676;
    margin-right: 2%;
}
.mobile .relatedsearches {
    background-color: #f7f7f5 !important;
    border-radius: 30px;
    padding: 0px 20px;
    width: fit-content;
    float: left;
    display: flex;
    align-items: center !important;
    justify-content: center;
    height: 40px;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 0;
}
.relatedsearches {
    background-color: #f7f7f5 !important;
    border-radius: 30px;
    padding: 0px 20px;
    width: fit-content;
    float: left;
    display: flex;
    align-items: center !important;
    justify-content: center;
    height: 40px;
    cursor: pointer;
    margin-right: 0.625rem;
    margin-top: 0.625rem;
}
.relatedsearches:hover{
    background-color: silver !important;
    transition: .2s ease-in-out;
}
.relatedsearches h6 {
    margin: 0;
    font-weight: 400;
}
.related {
    text-align: left;
}
.relatedmob {
    display: none;
}
.related h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #333333;
}
.related span {
    /*background: #fdfdfd;
    border: 0.7px solid silver;
    border-radius: 6px;
    padding:1%;
    margin: 1%;
    font-size: 13px;
    line-height: 16px;*/
}
.right-icon {
    font-size: 10px;
}
.mt-3 a {
    text-decoration: none;
}
.video-img {
    width: 100%;
    border-radius: 4px;
}
.related-video-img {
    width: 100%;
    border-radius: 8px 8px 0 0;
}
.related-video .card {

}
.related-video span {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;

    /* RB sec 1 */
    color: #522FFF;
}
.alert {
    font-size: 12px;
}
.related-title {
    padding-left: 5% !important;
    padding-top: 3% !important;
}
.related-footer {
    padding-left: 4%;
    padding-right: 4%;
}
.related-footer h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 10px !important;
    line-height: 12px;

    /* Dark grey */
    color: #767676 !important;
    position: absolute;
    bottom: 0;


}
.related-video h6{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    /* RB black text */
    color: #333333;
    text-align: left;
}
.save {
    font-size: 12px;
    margin-top: 1%;
    cursor: pointer;
    color: #522FFF !important;

    margin-right: 2%;
}
.saved-item {
    border: 0.5px solid #EBEBEB;
    border-radius: 10px;
}
.cancel {
    font-size: 12px;
    margin-top: 1%;
    cursor: pointer;
    color: #ED1E98 !important;

    margin-right: 2%;
}
.share-icon {
    margin-right: 2%;
}
.share-icon svg {
    height: 15px !important;
    width: 15px !important;
    border-radius: 50% !important;
}
.save-icon {
    font-size: 10px;
}
.modal-left {
    background: white !important;
}
.modal-body input {
    border-radius: 0;
    font-size: 14px;
    font-family: 'Kumbh Sans', sans-serif;
}
.modal-body label{
    border-radius: 0;
    font-size: 14px;
    font-family: 'Kumbh Sans', sans-serif !important;
    font-weight: 700;
}
.modal-body p{
    border-radius: 0;
    font-size: 14px;
    font-family: 'Kumbh Sans', sans-serif;
}
.navbar-brand {
    padding-left: 0rem;
}

/*----Top search bar------*/
.search-field-text1mob {
    /*box-shadow: 0 2px 5px 1px rgba(64,60,67,.16);*/
    height: 3rem;
    padding-left: 2% !important;
    font-size:  0.875rem;
    font-weight: 400;
    outline: none !important;
    border: none;

}
.search-field-text1tablet {
    border: none;
    border-radius: 6px;
    /*box-shadow: 0 2px 5px 1px rgba(64,60,67,.16);*/
    height: 3rem;
    padding-left: 2% !important;
    font-size:  0.875rem;
    font-weight: 400;
}

/*----Top filters font size. First level----*/
.filtermobile .filter-level1 a {
    font-size: 12px;
}
.filtermobile .filter-level1 {
    margin: 0;
}
.filternewmobile ul {
    list-style-type: none !important;
    white-space: nowrap;
    overflow-x:scroll;
    overflow-y: hidden;
    padding-left: .7rem;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.04);
    margin: 0;
}
.filternewmobile li {
    list-style-type: none !important;
    display: inline-block;
    float: unset;
    line-height: 40px;

}

.filternewdesktop ul {
    list-style-type: none !important;
    white-space: nowrap;
    padding-left: .7rem;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.04);
    margin: 0;
}
.filternewdesktop li {
    list-style-type: none !important;
    display: inline-block;
    float: unset;
    line-height: 40px;

}
.filtermobile li:first-child {
    margin-left: 1rem;
}

.filternewmobile a{
    display: block;
    text-align: center;
    margin-right: 14px;
    text-decoration: none;
    padding-bottom: 4%;
    cursor: pointer;
    height: 35px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;

    /* identical to box height */

    /* RB text */
    color: #333333;



}
.filternewdesktop a{
    display: block;
    text-align: center;
    margin-right: 2.563rem;
    text-decoration: none;
    padding-bottom: 4%;
    cursor: pointer;
    height: 35px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;

    /* identical to box height */

    /* RB text */
    color: #333333;



}

.filter-level1 a:hover{
    border-bottom: 2px solid #070707;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;

    /* RB text */
    color: #333333;
}
.filter-level1 li a {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;

    /* RB text */
    color: #333333;
}
.filter-level1 li{
    float: left;
    padding-left: 2.563rem
}
.filter-level1 li:first-child {
    padding-left: 0;
}
.filter-level1 ul {
    height: 30px !important;
}
.filter ul {
    height: 30px;
}
.more-dropdown  ul {
    height: fit-content !important;
}

.more-dropdown ul {
    height: fit-content !important;
}
.filter-level1 li:hover{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;

    /* RB text */
    color: #333333;
    background: white !important;
}
.filternewmobile .active{
    display: block;
    text-align: center;
    text-decoration: none;
    color: #070707;
    border-bottom: 4px solid #522FFF;
    height: 35px;
    border-radius: 4px;
}
.filternewdesktop .active{
    display: block;
    text-align: center;
    text-decoration: none;
    color: #070707;
    border-bottom: 2px solid #522FFF;
    height: 35px;
}
/*---Second level filters ----*/
.filter-level2 .active{
    background: #F5F3FF;
    border: 1px solid #EBEBEB;
    border-radius: 200px;
}
.mobile .filter-level2 .active{
    background: #F5F3FF;
    /* RB inactive */
    border: 1px solid #EBEBEB;
    border-radius: 200px;
}
.filter-level2 .active a {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #070707;
}
.mobile .filter-level2 .active a {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #070707;
}
.filter-level2 a{
    display: block;
    text-align: center;
    text-decoration: none;
    padding-bottom: 4%;
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #767676;
    margin: 0;
}
.filter-level2 {
    list-style-type: none !important;
    white-space: nowrap;
    padding-left: .7rem;
    margin-top: 1.5rem;
}
.mobile .filter-level2 {
    list-style-type: none !important;
    white-space: nowrap;
    overflow-x:scroll;
    overflow-y: hidden;
    padding-left: .7rem;
    margin-top: 1rem;
}
.filter-level2 li {
    border: 1px solid #EBEBEB;
    border-radius: 200px;
    text-align: center;
    margin-right: 1rem;
    padding: 12px 28px;
    list-style-type: none !important;
    display: inline-block;
    float: unset;
    line-height: 40px;
}
.mobile .filter-level2 li {
    border: 1px solid #EBEBEB;
    border-radius: 200px;
    text-align: center;
    margin-right: 6px;
    padding: 10px 18px;
    list-style-type: none !important;
    display: inline-block;
    float: unset;
    line-height: 40px;
}
.mobile .filter-level2 a{
    display: block;
    text-align: center;
    text-decoration: none;
    color: #070707;
    padding-bottom: 4%;
    cursor: pointer;
    font-size: 12px;
    margin: 0;
}
.tablet .filter-level2 a{
    display: block;
    text-align: center;
    margin-right: 16px;
    text-decoration: none;
    color: #070707;
    padding-bottom: 4%;
    cursor: pointer;
    font-size: 12px !important;
}
.mobile .filter-level2 li:hover {
    background: #F5F3FF;
    /* RB inactive */
    border: 1px solid #EBEBEB;
    border-radius: 200px;
}

/*--------Pagination styling--------*/
.pagination a {
    cursor: pointer;
}

/*-----Filters styling-------*/
.side-filters h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #070707;
}
.side-filters label {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #333333;
}
.side-filters{
    padding-top: 1.25rem;
    margin-top: 9.6rem;
    padding-left: 2rem;
    padding-right: 2rem;
}
/*----Images------*/
.images h6{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 118.52%;
    padding: 0;
    margin: 0;
}
.image-url {
    margin-bottom: -1rem !important;
}
.mobile .close {
    padding: .5rem;
}
.mobile .images {
    margin-bottom: 0.75rem;
}
.images {
    overflow: hidden;
    margin-bottom: 2rem;
}
.img-thumbnail {
    border: none !important;
}
.images img {
    /* height: 8.063rem;*/
    width: 100%;
    cursor: pointer !important;
}
.modal img {
    width: 100%;
}

/*---------Social Media Filter------*/
.social-filter li {
    border: 1px solid silver;
    border-radius: 30px;
    text-align: center;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    margin-right: 20px;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.social-filter li .social-icon {
    margin-right: 5px;
    border: 1px solid silver;
    border-radius: 50%;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
    float: left;
}
.social-filter .social-text {
    float: left;
    display: flex;
    align-items: center;
    padding-top: 5%;
}
.social-filter .active {
    border: none;
}