/*------Onboarding modal------*/
.modal-dialog-centered .modal-dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 498px;
    background: #FFFFFF;

}
.modal-dialog {
    border-radius: 8px;
}
.modal-content {
    border: none;
    border: 8px !important;
}
.modalclose {
    display: flex;
    justify-content: end;
}
.onboard-h6  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #070707;
    margin-top: 70px;
}
.onboard-p p{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #000000;
    margin-top: 29px;
    display: flex;
    justify-content: center;
}
.onboard-p .btn {
    margin-bottom: 61px;
    background: #070707;
    border-radius: 4px;
    color: white;
}
.modal-content {
    border-radius: 10px;
    margin-top:  0 !important;
}

/*------Login Modal-------*/
.modal-left .card {
    padding-bottom: 10% !important;
    border:none;
}
.modal-left .card h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #070707;
    margin-bottom: 2rem;
}
.google-login {
    background: #FFFFFF;
    border-radius: 6px;
    border: none;
    width: 100%;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000000 !important;
    height: 40px;
}
.google-login1 {
    background: #e8e8e8;
    border: none;
    width: 100%;
    font-family: 'Circular Std Book', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    color: #000000 !important;
    height: 52px;
    border-radius: 4px;
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.google-login1:disabled {
    cursor: not-allowed !important;

}
.my-facebook-button-class1 {
    background: white;
    border: none;
    width: 100% !important;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    color: #070707 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .375rem .75rem;
    height: 73px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 100px;
}

.my-facebook-button-class {
    background: white;
    border: none;
    border-radius: 6px;
    width: 100% !important;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #070707 !important;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .375rem .75rem;
}
.facebook-login span {
    width: 100%;
}
.authentication-modals li {
    font-size: 14px !important;
}
.authentication-modals img {
    width: 50%;
}
.my-facebook-button-class1 .social-media-facebook{
    /*background-image: url("../src/assets/facebook.png");*/
    height: 15px;
    width: 15px !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    margin-right: 1rem;
}
.social-media-facebook{
    /*background-image: url("../src/assets/facebook.png");*/
    height: 15px;
    width: 15px !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    margin-right: 2%;
}
.facebook{
    background-color: #3B5998 !important;
    border: #786E6C !important;
}
.bookmark {
    width: 11px !important;
    height: 21px;
    margin-right: 1rem;
}
.modal-body p {
    /* Gain access to: */
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 43px;
    text-align: center;
    color: #FFFFFF;


}
.modal-right h6 {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-top: 1rem;
    color: white;
}
.modal-right {
    padding-top: 2.688rem;
    padding-left: 3.063rem;
    padding-bottom: 4.063rem;
}

/*-----Feedback modal-------*/
.mobile-modal  .cancel-feedback {
    border: 2px solid #070707 ;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #070707;
    height: 42px;
    width: 130px;
}
.mobile-modal .btn {
   margin-bottom: 0 !important;
}
.logout-modal .logout-icon {
    margin-top: 3.75rem;
    margin-bottom: 1.688rem;
}
.logout-modal h6{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #070707;
}
.logout-modal .btn {
    margin-bottom: 4.75rem;
    margin-top: 2rem;
}
.kidding {
    border: 2px solid #070707;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #070707;
    height: 42px;
}
.kidding:hover{
    background: #070707;
    border-radius: 6px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: white;
    height: 42px;
}
.cancel-feedback {
    border: 2px solid #070707 ;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #070707;
    height: 42px;
    width: 130px;
}
.cancel-feedback:hover{
    background: #070707;
    border-radius: 6px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: white;
    height: 42px;
    width: 130px;
}
.feedback-textarea {
    border: 0.5px solid #BBBBBB;
    border-radius: 4px;
    height: 113px;
    width: 90%;
}
.send-feedback {
    background: blue;
    border-radius: 6px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: white;
    margin-left: 1.688rem;
    height: 42px;
    width: 130px;
}
.yeslog {
    background: #070707;
    border-radius: 6px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: white;
    margin-left: 1.688rem;
    height: 42px;
    width: 130px;
}
.yeslog:hover {
    background: white;
    border-radius: 6px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #070707;
    margin-left: 1.688rem;
    height: 42px;
    border: 2px solid #070707;
    width: 130px;
}
.content-preview p {
    color:  black !important;
    font-weight: 400 !important;
    text-align: left !important;
    font-size: 16px !important;
    line-height: 22px;
}
.upgrade-modal h6{
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    margin-top: 2.938rem;
}
.upgrade-modal p {
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 24px;
    text-align: center;
    color: #333333;
    margin-top: 2.938rem;
}
.btn-upgrade-modal {
    background: #130071;
    border-radius: 6px;
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    padding: 0.688rem 5rem;
}
.btn-upgrade-modal:hover {
    background: white;
    color: #130071;
    border: #130071 1px solid;
}