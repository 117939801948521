body{
  font-family: 'Circular Std Book', sans-serif;
  overflow-x: hidden !important;
  scroll-behavior: smooth !important;
}
.calendar-toggle::after {
  display: none !important;
}
#payment-form .alert-success {
  font-family: 'Kumbh Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* RB active/RB success */
  background: rgba(0, 148, 77, .8) !important;
  backdrop-filter: blur(2.5px);

  /* Note: backdrop-filter has minimal browser support */
  border-radius: 7px;
  color: white !important;
  position: relative;
  top:unset;
  transform: unset;

}
.cancel-modal .modal-dialog {
  width: 45rem;
}
.user-settings-modal .alert-success {
  font-family: 'Kumbh Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* RB active/RB success */
  background: rgba(0, 148, 77, .8) !important;
  backdrop-filter: blur(2.5px);

  /* Note: backdrop-filter has minimal browser support */
  border-radius: 7px;
  color: white !important;
  position: relative;
  top:unset;
  transform: unset;
}
.alert-success {
  font-family: 'Kumbh Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* RB active/RB success */
  background: rgba(0, 148, 77, .8) !important;
  backdrop-filter: blur(2.5px);

  /* Note: backdrop-filter has minimal browser support */
  border-radius: 7px;
  color: white !important;
  position: fixed;
  top:50%;
  transform: translate(0%,-50%);
}
.alert-warning {
  font-family: 'Kumbh Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  border-radius: 7px;
  position: fixed;
  top:50%;
  transform: translate(0%,-50%);
}
.mobile .alert-warning {
  font-family: 'Kumbh Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  border-radius: 7px;
  position: fixed;
  top:70%;
  transform: translate(50%,-50%);
}
.mobile .alert-success {
  font-family: 'Kumbh Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  background: rgba(0, 148, 77, 0.9) !important;
  backdrop-filter: blur(2.5px);
  border-radius: 7px;
  color: white !important;
  position: fixed;
  top:70%;
  transform: translate(50%,-50%);
}
.stats {
  padding: 4rem 17.188rem !important;
}
.stats .card-body {
  padding: 2.625rem 1.5rem ;
  background: white;
  border: 0.5px solid #EBEBEB;
  border-radius: 6px;
  height: 8.125rem;
}
.admin-icon-holder1 {
  background: rgba(0, 148, 77, .1);
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  float: left;
}
.admin-icon-holder2 {
  background: #fcf0f0;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  float: left;
}
.admin-icon-holder3 {
  background: #ecf6fd;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  float: left;
}
.stats-content {
  float: left;
  margin-left: 0.625rem;
}
.stats-content h6 {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #333333;
}
.stats-content h5 {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #070707;
}
.admin-icon-holder1 span {
  color: #00944d;
  font-size: 18px;
}
.admin-icon-holder2 span {
  color: #E06767;
  font-size: 18px;
}
.admin-icon-holder3 span {
  color: #39A1EA;
  font-size: 18px;
}
.impressions {
  margin: 3.5rem 7.5rem ;
  margin-top: 0 !important;
  padding: 3rem 4rem 3.125rem 2rem;
  background: white;
  border-radius: 6px;
}
.impression-icon {
  background: #F2EFFF;
  border-radius: 4px;
  padding: 3px;
  color: #0D004C;
}
.impressions .dropdown-toggle:after { content: none }
.impressions h6{
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #070707;
}
.impressions .dropdown-menu {
  left: 7.5rem !important;
  right:unset;
  width: 15.625rem;
  border-radius: 4px;
  padding-top: 0;
  padding-bottom: 0;
}
.impressions .dropdown-menu li{
  height: 2.5rem !important;
}
.impressions .dropdown-menu .active{
  background: #F2EFFF !important;
}
.impressions .dropdown-menu li:hover{
  background: #F2EFFF !important;
}
.impressions-filter .dropdown-menu {
  left: -8rem !important;
  right:unset;
  width: 10.625rem;
  border-radius: 4px;
  padding-top: 0;
  padding-bottom: 0;
}
.impressions1 {
  margin: 0rem 7.5rem ;
  margin-top: 0 !important;
  padding: 0;
  border-radius: 6px;
}
.impressions1 .card {
  padding: 2.125rem 2.5rem 2.125rem 2.5rem ;
  border: none;

}
.impressions1 .card .fullheight {
  height: 29.938rem;
  overflow: scroll;
}
.impressions1 .card h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;

  /* identical to box height */

  /* RB black */
  color: #070707;
}
.impressions1 .impression-icon {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.impressions1 table {
  margin-top: 2.344rem;
  table-layout: fixed;
}
.impressions1 table th {
  height: 4rem;
}
.impressions1 table td {
  height: 4rem;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  /* RB text */
  color: #333333;
}
.passed  td{
  color: #BBBBBB;
}
.passed-create td {
  opacity: 0.44;
}
