.certificate {
    background-image: url("../../../../assets/William Molina.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100% !important;
    overflow: hidden !important;
}
.certificate-preview {
    background-image: url("../../../../assets/William Molina.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 35rem;
}
.certificate-info {
    margin: 12.313rem 4.25rem 4.25rem 4.25rem;
    background-image: url("../../../../assets/Rectangle 201.png");
    background-repeat: no-repeat;
    background-size: 100% 900px;
    padding: 4rem 2rem 2rem 4rem ;

}
.certificate-info-preview {
    margin: 8.313rem 4.25rem 4.25rem 4.25rem;
    background: white;
    padding: 1.5rem 2rem 2rem 1.5rem ;
    clip-path: polygon(90% 0, 100% 20%, 100% 100%, 0 100%, 0 0);

}
.certificate-info h3 {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 51px;
    color: #000000;
}
.certificate-info-preview h3 {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 1px;
    color: #000000;
}
.certificate-info h2 {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 55px;
    margin-top: 2.75rem;
    margin-bottom: 0;

    /* identical to box height */
    text-transform: uppercase;

    color: #000000;
}
.certificate-info-preview h2 {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 55px;
    margin-top:2rem;

    /* identical to box height */
    text-transform: uppercase;

    color: #000000;
}
.certificate-info h5 {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 40px;
    margin-top: 1.625rem;

    /* identical to box height */

    color: #000000;
}
.certificate-info hr {
    margin-top: 0;
}
.certificate-info-preview h5 {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 40px;

    /* identical to box height */

    color: #000000;
}
.avatar-holder {
    background-color: #D9D9D9;
    height: 7.375rem;
    width: 7.375rem;
    border-radius: 50%;

}
.avatar-holder-mobile {
    background-color: #D9D9D9;
    height: 4.375rem;
    width: 4.375rem;
    border-radius: 50%;
}
.avatar-holder-mobile img{
    border-radius: 50%;
}
.mobile .avatar-holder-container {
    background: #FBFBFF;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    margin-top: -2.5rem;
    z-index: 100;
    position: absolute;
}
.avatar-holder-container {
    background: #FBFBFF;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8rem;
    width: 8rem;
    border-radius: 50%;
    margin-top: -4rem;
    z-index: 100;
    position: absolute;
}
.cert-box {
    background: #FFFFFF;
    border: 0.919103px solid #F0F0F0;
    box-shadow: 0px 2.14013px 4.28027px rgba(0, 0, 0, 0.07);
    border-radius: 6.4204px;
    padding: 0.75rem 1.125rem;
}
.cert-box h6{
    font-style: normal;
    font-weight: 500;
    font-size: 14.7056px;
    line-height: 125.5%;
    color: #000000;
    margin-top: 0.75rem;
}
.cert-box .dropdown-menu{
    margin-top: 0;
}
.cert-box .dropdown-toggle::after{
    display: none;
}
.cert-box button {
    padding: 0;
}
.workshop .dropdown-menu{
    margin-top: 0;
}
.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}