.badge-container {
    background-image: url("../../../../assets/Group 90141.png");
    background-size: cover;
    height: 774px;
    width: 774px;
    padding-top: 127px;
}
.badge-container h6 {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 56.9197px;
    line-height: 78px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #5233EC;

}